const MainIconTemp3 = ({ fillColor = "#808080" }) => {
    const icon = `
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 6C12 9.31371 9.31371 12 6 12C2.68629 12 0 9.31371 0 6C0 2.68629 2.68629 0 6 0C9.31371 0 12 2.68629 12 6ZM3.42 6C3.42 7.42489 4.57511 8.58 6 8.58C7.42489 8.58 8.58 7.42489 8.58 6C8.58 4.57511 7.42489 3.42 6 3.42C4.57511 3.42 3.42 4.57511 3.42 6Z" fill="${fillColor}"/>
        </svg>`;

    const svgDataUrl = `data:image/svg+xml;base64,${btoa(icon)}`;

    return (
        <div>
            <img src={svgDataUrl} alt="MainIconTemp3" />
        </div>
    );
}

export default MainIconTemp3;
