import React, { useEffect, useState } from 'react';
import { Table, Select, Pagination, DatePicker, Tooltip, message, Button, Card } from 'antd';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import _ from "lodash";
import { CopyOutlined, RedoOutlined } from '@ant-design/icons';
import { AppDispatch, RootState } from '../../store';
import { useParams } from 'react-router-dom';
import { setSessionTabIndex } from '../../redux/slices/chargerSlice';
import { fetchChargerStatusForErrorTab } from '../../redux/services/chargerSevice';
import { PAGESIZE } from '../../utils/common';


const ErrorJsonDataDisplay = (props: any) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [filteredData, setFilteredData] = useState([]);
    const dispatch = useDispatch<AppDispatch>();
    // const selectedChargerDetails = useSelector((state) => state.sites.selectedChargerDetails);
    const selectedCustomerName = useSelector((state: RootState) => state.userInfo.selectedCustomerName);
    const { fetchingChargerDataForErrorTab, errorTabChargers, dataTobeDis } = useSelector((state: RootState) => state.chargers);
    const chargerId: any = useParams().chargerId;
    // const filteredData = dataTobeDis && dataTobeDis[chargerId] && dataTobeDis?.filter((item: any) => item.id === Number(chargerId)) || []
    const [fromDate, setFromDate] = useState(dayjs().startOf('day'));
    const [toDate, setToDate] = useState(dayjs().endOf('day'));

    useEffect(() => {
        dispatch(setSessionTabIndex('3'))
    }, [])

    useEffect(() => {
        if (dataTobeDis.length > 0) {
            const x = dataTobeDis?.filter((item: any) => item?.connectors[0]?.status === 'Faulted');
            setFilteredData(x || []);
        }
    }, [dataTobeDis]);

    useEffect(() => {
        if (toDate) {
            dispatch(fetchChargerStatusForErrorTab({ selectedCustomerName, chargerId, fromDate: fromDate?.toISOString(), toDate: toDate?.toISOString() }));
        }
    }, [chargerId, toDate]);

    const startIndex = (currentPage - 1) * PAGESIZE;
    const endIndex = startIndex + PAGESIZE;

    const currentPageData = filteredData?.slice(startIndex, endIndex);

    const { RangePicker } = DatePicker;

    const handleCopy = (text: any) => {
        navigator.clipboard.writeText(text).then(() => {
            message.success('Copied to clipboard');
        }).catch((err) => {
            message.error('Failed to copy');
        });
    };

    const columns = [
        {
            title: 'Connector Id',
            render: (record: any) => <Tooltip color='blue'
                title={
                    <div>
                        {'Connector Id: ' + record && record.connectors && record.connectors[0] && record.connectors[0]._id}
                        <Button
                            title='Copy'
                            type="link"
                            icon={<CopyOutlined style={{ color: 'white' }} />}
                            style={{ marginLeft: 8 }}
                            onClick={() => handleCopy(record && record.connectors && record.connectors[0] && record.connectors[0]._id)}
                        />
                    </div>
                }
            >
                <span><u>{record && record.connectors && record.connectors[0] && record.connectors[0].connectorId}</u></span>
            </Tooltip>,
        },
        {
            title: 'Endpoint',
            render: (record: any) => <Tooltip color='blue'
                title={
                    <div>
                        {'Chargestation ID: ' + record && record.connectors && record.connectors[0] && record.connectors[0].chargestation}
                        <Button
                            title='Copy'
                            type="link"
                            icon={<CopyOutlined style={{ color: 'white' }} />}
                            style={{ marginLeft: 8 }}
                            onClick={() => handleCopy(record && record.connectors && record.connectors[0] && record.connectors[0].chargestation)}
                        />
                    </div>
                }
            >
                <span><u>{record.endpoint}</u></span>
            </Tooltip>,
        },
        {
            title: 'Location',
            dataIndex: 'location',
            render: (location: any) => {
                const { address } = location;
                return `${address.streetAndNumber}, ${address.city}, ${address.state}, ${address.country}, ${address.postalCode}`;
            },
        },
        {
            title: 'Date',
            dataIndex: 'event_time',
            key: 'event_time',
            render: (event_time: any) => {
                const eventTime = dayjs(event_time);
                return eventTime.format('MM-DD-YYYY HH:mm');
            },
            sorter: (a: any, b: any) => dayjs(a.event_time).unix() - dayjs(b.event_time).unix(),
        },
        {
            title: 'Message',
            render: (record: any) => {
                return record && record.connectors && record.connectors[0] && record.connectors[0].status || 'error'
            }
        },
    ];

    const onOk = (value: any) => {
        setFromDate(value && value[0] ? value[0] : undefined);
        setToDate(value && value[1] ? value[1] : undefined);
    };

    return (
        <Card size='small'>
            <div style={{ marginBottom: '10px' }}>
                <RangePicker
                    showTime={{ format: 'HH:mm' }}
                    defaultValue={[dayjs().startOf('day'), dayjs().endOf('day')]}
                    format="YYYY-MM-DD HH:mm"
                    onChange={onOk}
                />
            </div>
            <Table
                dataSource={currentPageData}
                columns={columns}
                size='small'
                pagination={false}
                loading={fetchingChargerDataForErrorTab}
                scroll={{ x: 'auto' }}
                bordered
                footer={() => (
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Pagination
                            showSizeChanger
                            showQuickJumper
                            showTotal={(total, range) => `Showing ${range[0]}-${range[1]} of ${total} items`}
                            onChange={(page) => setCurrentPage(page)}
                            current={currentPage}
                            total={filteredData.length}
                            defaultPageSize={PAGESIZE}
                            pageSizeOptions={['8', '16', '24', '32']}
                        />
                        <Tooltip color="blue" title="Refresh Data" className='clickableOpacity'>
                            <RedoOutlined onClick={() => dispatch(fetchChargerStatusForErrorTab({ selectedCustomerName, chargerId, fromDate: fromDate?.toISOString(), toDate: toDate?.toISOString() }))} />
                        </Tooltip>
                    </div>
                )}
            />
        </Card>
    );
}

export default ErrorJsonDataDisplay;
