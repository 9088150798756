import { Button, message, Modal, Pagination, Select, Table, Tooltip } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useMemo, useState } from 'react';
import { AnyIfEmpty, useDispatch, useSelector } from 'react-redux';
import _, { toUpper } from 'lodash';
import { CopyOutlined, InfoCircleOutlined, RedoOutlined } from '@ant-design/icons';
import duration from 'dayjs/plugin/duration';
import { RootState } from '../../store';
import { PAGESIZE, timezoneAbbreviations } from '../../utils/common';
import { setSessionTabIndex } from '../../redux/slices/chargerSlice';

dayjs.extend(duration);

const { Option } = Select;

const TodaySessionJsonDataDisplay = (props: any) => {
    const dispatch = useDispatch();
    const [currentPage, setCurrentPage] = useState(1);
    const [filterValues, setFilterValues] = useState({});
    const [selectedId, setSelectedId] = useState("");
    const [sessionDataToDisplay, setSessionDataToDisplay] = useState<any>(null);



    const { sessionData, isLoadingSessionData: sessionDataLoading, sessionTabIndex } = useSelector((state: RootState) => state.chargers);
    const { selectedTimezoneAbb, selectedTimezone } = useSelector((state: RootState) => state.userInfo);
    const todaysDate = new Date();

    const todaysSessionData = sessionData.filter(item => {
        if (item.metrics && item.metrics.chargingStart && item.metrics.chargingStop) {
            const chargingStart = new Date(item.metrics.chargingStart);
            const chargingStop = new Date(item.metrics.chargingStop);
            // Check if chargingStart or chargingStop is today
            return chargingStart.getDate() === todaysDate.getDate() && chargingStart.getMonth() === todaysDate.getMonth() && chargingStart.getFullYear() === todaysDate.getFullYear() ||
                chargingStop.getDate() === todaysDate.getDate() && chargingStop.getMonth() === todaysDate.getMonth() && chargingStop.getFullYear() === todaysDate.getFullYear();
        }
        return false;
    });

    const handleCopy = (text: string) => {
        navigator.clipboard.writeText(text).then(() => {
            message.success('Copied to clipboard');
        }).catch((err) => {
            message.error('Failed to copy');
        });
    };

    function truncateString(str: string | any[] | null, length = 8) {
        if (str === null) {
            return ''
        }
        if (str.length > length) {
            return `...${str.slice(-length)}`;
        }
        return str;
    }

    // Columns definitions
    const metricsColumns = [
        { title: 'Field', dataIndex: 'field', key: 'field' },
        { title: 'Value', dataIndex: 'value', key: 'value' }
    ];

    const costColumns = [
        { title: 'Field', dataIndex: 'field', key: 'field' },
        { title: 'Value', dataIndex: 'value', key: 'value' }
    ];

    // Safe metrics data extraction
    const metricsData = [
        {
            field: 'Charging Start',
            value: sessionDataToDisplay?.metrics?.chargingStart
                ? dayjs(sessionDataToDisplay.metrics.chargingStart).format('YYYY-MM-DD HH:mm:ss A')
                : 'N/A'
        },
        {
            field: 'Charging Stop',
            value: sessionDataToDisplay?.metrics?.chargingStop
                ? dayjs(sessionDataToDisplay.metrics.chargingStop).format('YYYY-MM-DD HH:mm:ss A')
                : 'N/A'
        },
        {
            field: 'Energy Consumed',
            value: sessionDataToDisplay?.metrics?.energyConsumed
                ? `${sessionDataToDisplay.metrics.energyConsumed}w`
                : 'N/A'
        },
        {
            field: 'Energy Period',
            value: sessionDataToDisplay?.metrics?.energyPeriod
                ? `${sessionDataToDisplay.metrics.energyPeriod}`
                : 'N/A'
        },
        {
            field: 'Meter Start',
            value: sessionDataToDisplay?.metrics?.meterStart
                ? sessionDataToDisplay.metrics.meterStart
                : 'N/A'
        },
        {
            field: 'Meter Stop',
            value: sessionDataToDisplay?.metrics?.meterStop
                ? sessionDataToDisplay.metrics.meterStop
                : 'N/A'
        },
        {
            field: 'Timezone',
            value: sessionDataToDisplay?.metrics?.timezone
                ? sessionDataToDisplay.metrics.timezone
                : 'N/A'
        }
    ];

    // Safe cost data extraction
    const costBreakdown = sessionDataToDisplay?.cost?.breakdown?.[0] || {};

    const costData = [
        {
            field: 'Rate Name',
            value: costBreakdown?.rateName || 'N/A'
        },
        {
            field: 'Unit Price',
            value: costBreakdown?.unitPrice
                ? `${costBreakdown.unitPrice}`
                : 'N/A'
        },
        {
            field: 'Quantity',
            value: costBreakdown?.quantity || 'N/A'
        },
        {
            field: 'Total Cost',
            value: costBreakdown?.totalCost
                ? `${costBreakdown.totalCost.toFixed(2)}`
                : 'N/A'
        },
        {
            field: 'Before Tax',
            value: costBreakdown?.beforeTax
                ? `${costBreakdown.beforeTax.toFixed(2)}`
                : 'N/A'
        },
        {
            field: 'Total Tax',
            value: costBreakdown?.totalTax
                ? `${costBreakdown.totalTax.toFixed(2)}`
                : 'N/A'
        },
        {
            field: 'Currency',
            value: sessionDataToDisplay?.cost?.currency
                ? toUpper(sessionDataToDisplay.cost.currency)
                : 'N/A'
        }
    ];

    const energyReportColumns = [
        { title: 'Field', dataIndex: 'field', key: 'field' },
        { title: 'Value', dataIndex: 'value', key: 'value' }
    ];

    const energyReportData = [
        {
            field: 'Power',
            value: `${sessionDataToDisplay?.energy_report?.power?.value ?? 'N/A'} ${sessionDataToDisplay?.energy_report?.power?.unit ?? ''}`
        },
        {
            field: 'Current',
            value: `${sessionDataToDisplay?.energy_report?.current?.value ?? 'N/A'} ${sessionDataToDisplay?.energy_report?.current?.unit ?? ''}`
        },
        {
            field: 'Energy Meter',
            value: `${sessionDataToDisplay?.energy_report?.energy_meter?.value ?? 'N/A'} ${sessionDataToDisplay?.energy_report?.energy_meter?.unit ?? ''}`
        },
        {
            field: 'State of Charge',
            value: `${sessionDataToDisplay?.energy_report?.state_of_charge?.value ?? 'N/A'} ${sessionDataToDisplay?.energy_report?.state_of_charge?.unit ?? ''}`
        },
        {
            field: 'Timestamp',
            value: sessionDataToDisplay?.energy_report?.timestamp ? dayjs(sessionDataToDisplay?.energy_report?.timestamp ?? '').format('YYYY-MM-DD HH:mm:ss A') : 'N/A'
        }
    ];

    const getTimezoneAbbreviation = () => {
        const offsetMinutes = new Date().getTimezoneOffset();
        const offsetHours = Math.floor(Math.abs(offsetMinutes) / 60);
        const offsetSign = offsetMinutes > 0 ? '-' : '+';
        const offsetString = `${offsetSign}${String(offsetHours).padStart(2, '0')}:${String(Math.abs(offsetMinutes) % 60).padStart(2, '0')}`;
        return timezoneAbbreviations[offsetString] || `GMT${offsetString}`;
    };

    // Get browser's local timezone abbreviation
    const localTimezoneAbbreviation = getTimezoneAbbreviation();

    // Dynamically generate columns based on the keys in the JSON data
    const columns: any = [
        {
            title: 'Session Id',
            dataIndex: 'session_id',
            key: 'session_id',
            filters: Array.from(new Set(todaysSessionData.map(item => item.session_id)))
                .map(sessionId => ({
                    text: truncateString(sessionId),
                    value: sessionId
                })),
            onFilter: (value: any, record: any) => record.session_id === value,
            render: (text: any, record: any) => (
                <Tooltip title={record.session_id} color='blue'>
                    <span style={{ cursor: 'pointer' }}>
                        <a className='underline' onClick={() => setSessionDataToDisplay(record)}>
                            {truncateString(record.session_id)}
                        </a>
                        <Button
                            title='Copy'
                            type="link"
                            icon={<CopyOutlined />}
                            style={{ marginLeft: 8 }}
                            onClick={() => handleCopy(record.session_id)}
                        />
                    </span>
                </Tooltip>
            ),
        },
        {
            title: 'Connector Id',
            dataIndex: 'connector',
            key: 'connector',
            filters: Array.from(new Set(todaysSessionData.map(item => item.connector).filter(connector => !_.isEmpty(connector))))
                .map(connector => ({
                    text: truncateString(connector),
                    value: connector
                })),
            onFilter: (value: any, record: any) => record.connector === value,
            render: (record: any) => (
                <Tooltip title={record} color='blue'>
                    <span>
                        {record ? truncateString(record) : 'N/A'}
                        {record && (
                            <Button
                                title='Copy'
                                type="link"
                                icon={<CopyOutlined />}
                                style={{ marginLeft: 8 }}
                                onClick={() => handleCopy(record)}
                            />
                        )}
                    </span>
                </Tooltip>
            ),
        },
        {
            title: `Start Time (${selectedTimezoneAbb})`,
            dataIndex: 'startTime',
            key: 'startTime',
            filters: Array.from(new Set(todaysSessionData.map(item => {
                const startTime = _.get(item, "cost.breakdown.0.period.startTime", '');
                return dayjs(startTime).isValid() ? selectedTimezone === 'UTC' ? dayjs.utc(startTime).format("HH:mm A") : dayjs(startTime).tz(selectedTimezone).format("HH:mm A") : null;
            }).filter(startTime => startTime !== null)))
                .map(startTime => ({
                    text: startTime,
                    value: startTime
                })),
            onFilter: (value: any, record: any) => selectedTimezone === 'UTC' ? dayjs.utc(_.get(record, "cost.breakdown.0.period.startTime", '')).format("HH:mm A") : dayjs(_.get(record, "cost.breakdown.0.period.startTime", '')).tz(selectedTimezone).format("HH:mm A") === value,
            render: (text: any, record: any) => {
                const startTime = _.get(record, "cost.breakdown.0.period.startTime", '');
                const endTime = _.get(record, "cost.breakdown.0.period.endTime", '');
                const sameDay = dayjs(startTime).isSame(dayjs(endTime), 'day');
                return startTime ? sameDay ? selectedTimezone === 'UTC' ? dayjs.utc(startTime).format("HH:mm A") : dayjs(startTime).tz(selectedTimezone).format("HH:mm A") : selectedTimezone === 'UTC' ? dayjs.utc(startTime).format("HH:mm A") : dayjs(startTime).tz(selectedTimezone).format("MM-DD-YYYY HH:mm A") : '';
            },
        },
        {
            title: `End Time (${selectedTimezoneAbb})`,
            dataIndex: 'endTime',
            key: 'endTime',
            filters: Array.from(new Set(todaysSessionData.map(item => {
                const endTime = _.get(item, "cost.breakdown.0.period.endTime", '');
                return dayjs(endTime).isValid() ? selectedTimezone === 'UTC' ? dayjs.utc(endTime).format("HH:mm A") : dayjs(endTime).tz(selectedTimezone).format("HH:mm A") : null;
            }).filter(endTime => endTime !== null)))
                .map(endTime => ({
                    text: endTime,
                    value: endTime
                })),
            onFilter: (value: any, record: any) => selectedTimezone === 'UTC' ? dayjs.utc(_.get(record, "cost.breakdown.0.period.endTime", '')).format("HH:mm A") : dayjs(_.get(record, "cost.breakdown.0.period.endTime", '')).tz(selectedTimezone).format("HH:mm A") === value,
            render: (text: any, record: any) => {
                const startTime = _.get(record, "cost.breakdown.0.period.startTime", '');
                const endTime = _.get(record, "cost.breakdown.0.period.endTime", '');
                const sameDay = dayjs(startTime).isSame(dayjs(endTime), 'day');
                return startTime ? sameDay ? selectedTimezone === 'UTC' ? dayjs.utc(endTime).format("HH:mm A") : dayjs(endTime).tz(selectedTimezone).format("HH:mm A") : selectedTimezone === 'UTC' ? dayjs.utc(endTime).format("HH:mm A") : dayjs(endTime).tz(selectedTimezone).format("MM-DD-YYYY HH:mm A") : '';
            },
        },
        {
            title: 'Consumption (kWh)',
            dataIndex: 'energyConsumed',
            key: 'energyConsumed',
            filters: Array.from(new Set(todaysSessionData.map(item => {
                const energyConsumed = _.get(item, "metrics.energyConsumed", 0);
                return _.round(energyConsumed / 1000, 2).toFixed(2);
            })))
                .map(energyConsumed => ({
                    text: energyConsumed,
                    value: energyConsumed
                })),
            onFilter: (value: any, record: any) => {
                const energyConsumed = _.get(record, "metrics.energyConsumed", 0);
                return _.round(energyConsumed / 1000, 2).toFixed(2) === value;
            },
            render: (text: any, record: any) => record.metrics && record.metrics.energyConsumed ? _.round(record.metrics.energyConsumed / 1000, 2).toFixed(2) : '',
        },
        {
            title: 'Cost',
            dataIndex: 'cost',
            key: 'cost',
            filters: Array.from(new Set(todaysSessionData.map(item => {
                const amount = _.get(item, "cost.amount", 0);
                const currency = _.get(item, "cost.currency", '');
                return `${_.round(amount, 2).toFixed(2)} ${toUpper(currency)}`;
            })))
                .map(cost => ({
                    text: cost,
                    value: cost
                })),
            onFilter: (value: any, record: any) => {
                const amount = _.get(record, "cost.amount", 0);
                const currency = _.get(record, "cost.currency", '');
                return `${_.round(amount, 2).toFixed(2)} ${toUpper(currency)}` === value;
            },
            render: (text: any, record: any) => record && record.cost && (record.cost.amount || record.cost.amount === 0) ? _.round(record.cost.amount, 2).toFixed(2) + ' ' + (record.cost.currency ? toUpper(record.cost.currency) : '') : '',
        },
        {
            title: (
                <>
                    SoC (%) [start - end]
                    <Tooltip color='blue' title="SoC start is defaulted to 0 for non-DC chargers and chargers where SoC start is unavailable.">
                        <InfoCircleOutlined style={{ marginLeft: 8 }} />
                    </Tooltip>
                </>
            ),
            dataIndex: ['energy_report', 'state_of_charge'],
            key: 'state_of_charge',
            filters: Array.from(new Set(todaysSessionData.map(item => {
                const stateOfCharge = _.get(item, 'energy_report.state_of_charge.value', null);
                return stateOfCharge ? _.round(parseFloat(stateOfCharge), 2).toFixed(2) : null;
            }).filter(stateOfCharge => stateOfCharge !== null)))
                .map(stateOfCharge => ({
                    text: `${stateOfCharge}%`,
                    value: stateOfCharge
                })),
            onFilter: (value: any, record: any) => {
                const stateOfCharge = _.get(record, 'energy_report.state_of_charge.value', null);
                return _.round(parseFloat(stateOfCharge), 2).toFixed(2) === value;
            },
            render: (text: any, record: any) => {
                const stateOfChargeStarted = sessionData.filter(item => item.session_id === record.session_id && item.status === 'Started');
                const stateOfCharge = _.get(record, 'energy_report.state_of_charge.value', null);
                const stateOfChargeStartedVal: any = stateOfChargeStarted[0].energy_report.state_of_charge.unit ? stateOfChargeStarted[0].energy_report.state_of_charge.value : 0;
                return stateOfCharge ? `${_.round(parseFloat(stateOfChargeStartedVal), 2).toFixed(2)} - ${_.round(parseFloat(stateOfCharge), 2).toFixed(2)}` : 'N/A';
            }
        },
        {
            title: 'Duration (Hours)',
            dataIndex: 'duration',
            key: 'duration',
            render: (text: any, record: any) => {
                const startTime = _.get(record, "cost.breakdown.0.period.startTime", '');
                const endTime = _.get(record, "cost.breakdown.0.period.endTime", '');
                const diff = dayjs.duration(dayjs(endTime).diff(dayjs(startTime)));
                const formattedDuration = `${String(diff.hours()).padStart(2, '0')}:${String(diff.minutes()).padStart(2, '0')}:${String(diff.seconds()).padStart(2, '0')}`;
                return formattedDuration;
            },
        },
    ];

    const uniqueDates = useMemo(() => {
        const datesSet = new Set(todaysSessionData.map(item => dayjs(item.event_time).format('MM-DD-YYYY')));
        return Array.from(datesSet);
    }, [todaysSessionData]);


    useEffect(() => {
        dispatch(setSessionTabIndex('1'))
    }, [])

    const startIndex = (currentPage - 1) * PAGESIZE;
    const endIndex = startIndex + PAGESIZE;

    const getNestedValue = (item: any, columnName: any) => {
        if (columnName === 'startTime') {
            return _.get(item, "cost.breakdown.0.period.startTime", '');
        } else if (columnName === 'endTime') {
            return _.get(item, "cost.breakdown.0.period.endTime", '');
        } else if (columnName === 'quantity') {
            return _.get(item, "cost.breakdown.0.quantity", '');
        } else if (columnName === 'cost') {
            return _.get(item, "cost.amount", '');
        } else if (columnName === 'duration') {
            const startTime = _.get(item, "cost.breakdown.0.period.startTime", '');
            const endTime = _.get(item, "cost.breakdown.0.period.endTime", '');
            const diff = dayjs.duration(dayjs(endTime).diff(dayjs(startTime)));
            const formattedDuration = `${String(diff.hours()).padStart(2, '0')}:${String(diff.minutes()).padStart(2, '0')}:${String(diff.seconds()).padStart(2, '0')}`;
            return formattedDuration;
        } else if (columnName === 'energyConsumed') {
            const energy = _.get(item, "metrics.energyConsumed", '');
            return (energy / 1000).toFixed(2); // Divide by 1000 and round to 2 decimal places
        } else if (columnName === 'connector') {
            let cnId = _.get(item, 'connector', null);
            return cnId ? cnId : '';
        } else if (columnName === 'state_of_charge') {
            const stateOfCharge = _.get(item, "energy_report.state_of_charge.value", '');
            return stateOfCharge ? parseFloat(stateOfCharge).toFixed(2) : 'N/A'; // Two decimal places
        }
        else {
            return item[columnName];
        }
    };

    const filteredData = todaysSessionData && todaysSessionData.filter(item =>
        Object.entries(filterValues).every(([key, value]: any) => {
            const itemValue = getNestedValue(item, key);

            if (key === 'event_time') {
                const formattedEventTime = dayjs(itemValue).format('MM-DD-YYYY');
                return value ? formattedEventTime.includes(value) : true;
            } else if (key === 'startTime' || key === 'endTime') {
                return value ? itemValue.includes(value) : true;
            }

            return value ? itemValue.toString().includes(value) : true;
        }) && (selectedId ? item['session_id'] === selectedId : true)
    );

    const currentPageData = filteredData.slice(startIndex, endIndex);

    return (
        <>
            {/* <div style={{ marginBottom: '10px', display: 'flex', flexWrap: 'wrap', alignItems: 'center', gap: '5px' }}>
                <span><b>Filters: </b>&nbsp;</span>
                {columns.filter(col => col.dataIndex !== 'event_time').map(column => {
                    const uniqueValues = new Set();
                    return (
                        <div key={column.dataIndex} style={{ marginRight: '10px', flex: '0 1' }}>
                            <Select
                                placeholder={`Select ${column.title}`}
                                style={{ width: '100%' }}
                                onChange={(value) => handleFilterChange(column.dataIndex, value)}
                                value={filterValues[column.dataIndex] || undefined}
                                allowClear
                                showSearch
                            >
                                {todaysSessionData.map(item => {
                                    const value = getNestedValue(item, column.dataIndex);
                                    if (value && !uniqueValues.has(value)) {
                                        uniqueValues.add(value);
                                        return (
                                            <Option key={value} value={value}>
                                                {column.dataIndex === 'startTime' || column.dataIndex === 'endTime' ? dayjs(value).format('HH:mm A') : value}
                                            </Option>
                                        );
                                    }
                                    return null;
                                })}
                            </Select>
                        </div>
                    );
                })}
            </div> */}
            <Table
                dataSource={currentPageData}
                columns={columns}
                // style={{ marginTop: '10px' }}
                pagination={false}
                loading={sessionDataLoading}
                bordered
                size='small'
                scroll={{ x: 'auto' }}
                footer={() => (
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Pagination
                            pageSize={PAGESIZE}
                            showTotal={(total, range) => `Showing ${range[0]}-${range[1]} of ${total} items`}
                            onChange={setCurrentPage}
                            current={currentPage}
                            total={filteredData.length}
                        />
                        <Tooltip title="Refresh Data" className='clickableOpacity' color='blue'>
                            <RedoOutlined onClick={() => props.handleSessionChanges(1)} />
                        </Tooltip>
                    </div>
                )}
            />
            <Modal open={sessionDataToDisplay !== null} onClose={() => setSessionDataToDisplay(null)} footer={null} destroyOnClose onCancel={() => setSessionDataToDisplay(null)} title={`Session Details - ${sessionDataToDisplay && sessionDataToDisplay.session_id}`}>
                <div style={{ height: '500px', overflowY: 'auto' }}>
                    <Table
                        title={() => <b><u>Metrics</u></b>}
                        columns={metricsColumns}
                        dataSource={metricsData}
                        pagination={false}
                        // rowKey="field"
                        showHeader={false}
                        style={{ marginBottom: '16px' }}
                    />
                    <Table
                        title={() => <b><u>Cost Breakdown for {sessionDataToDisplay && sessionDataToDisplay.cost && sessionDataToDisplay.cost.amount} {toUpper(sessionDataToDisplay && sessionDataToDisplay.cost && sessionDataToDisplay.cost.currency)}</u></b>}
                        columns={costColumns}
                        dataSource={costData}
                        pagination={false}
                        showHeader={false}
                    // rowKey="field"
                    />
                    <Table
                        title={() => <b><u>Energy Record</u></b>}
                        columns={energyReportColumns}
                        dataSource={energyReportData}
                        pagination={false}
                        showHeader={false}
                    //   rowKey="field"
                    />
                </div>
            </Modal>
        </>
    );
};

export default TodaySessionJsonDataDisplay;
