// src/reducers/rootReducer.ts
import { combineReducers } from '@reduxjs/toolkit';
import userInfoReducer from './redux/slices/customerSlice';
import chargerReducer from './redux/slices/chargerSlice';
import powerReducer from './redux/slices/powerSlice';
import cameraReducer from './redux/slices/cameraSlice';
import dashBoardReducer from './redux/slices/dashboardSlice';

// Combine your slices here
const rootReducer = combineReducers({
    userInfo: userInfoReducer,
    chargers: chargerReducer,
    power: powerReducer,
    camera: cameraReducer,
    dashboard: dashBoardReducer
    // Add other reducers here as needed
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
