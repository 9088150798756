import { Result, Button } from "antd";
import { useNavigate } from "react-router-dom";

const NoPermissionPage = () => {
    const navigate = useNavigate();

    return (
        <Result
            status="403"
            title="Access Denied"
            subTitle="Sorry, you don't have permission to access this page."
            extra={
                <Button
                    type="primary"
                    onClick={() => navigate("/")}
                    style={{ fontFamily: "SF Pro Display" }} // Optional: Ensure font consistency
                >
                    Go Back to Home
                </Button>
            }
        />
    );
};

export default NoPermissionPage;
