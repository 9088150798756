// src/slices/userInfoSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { fetchAllPermittedCustomers, fetchAllCustomerSites, fetchUserInfo, fetchGeneralDetails, logoutUser, Site, fetchWeatherData } from '../services/customerService';
import { PermittedCustomer, UserInfo, UserInfoState, CustomerSite, GeneralDetails } from '../types/customerTypes';
import { theme } from 'antd';
import dayjs from 'dayjs';

const initialState: UserInfoState = {
    weatherInfo: null,
    weatherInfoError: '',
    weatherInfoLoading: false,
    theme: 0,
    userInfo: null,
    loading: false,
    error: null,
    selectedSite: '',
    selectedCustomerName: '',
    errorLogout: null,
    loadingLogout: false,
    detailedSites: [],
    detailedSitesLoading: false,
    detailedSitesError: null,
    loggedOut: false,
    permittedCustomers: [],
    generalDetailsLoading: false,
    generalDetailsError: null,
    permittedCustomersLoading: false,
    fromDate: dayjs().startOf('day').format('YYYY-MM-DD'),
    toDate: dayjs().endOf('day').format('YYYY-MM-DD'),
    permittedCustomersError: '',
    customerSites: [], // Initial state for customer sites
    customerSitesLoading: false,
    customerSitesError: null,
    generalDetails: {} as GeneralDetails,
    selectedTimezone: 'US/Pacific',
    selectedTimezoneAbb: 'PST',
};

const userInfoSlice = createSlice({
    name: 'userInfo',
    initialState,
    reducers: {
        clearUserInfo(state) {
            // state.userInfo = null;
            state.generalDetails = {} as GeneralDetails;
            state.selectedTimezone = 'US/Pacific';
            state.selectedTimezoneAbb = 'PST';
            state.weatherInfo = null;
            state.weatherInfoError = '';
            state.weatherInfoLoading = false;
            state.loading = false;
            state.error = null;
            // state.selectedCustomerName = '';
            state.generalDetailsLoading = false;
            state.detailedSites = [];
            state.theme = 0;
            // state.detailedSitesLoading = false;
            state.detailedSitesError = null;
            state.selectedSite = '';
            // state.permittedCustomers = [];
            // state.permittedCustomersLoading = false;
            // state.permittedCustomersError = null;
            state.customerSites = []; // Reset customer sites when clearing user info
            state.customerSitesLoading = false;
            state.customerSitesError = null;
            state.fromDate = dayjs().startOf('day').format('YYYY-MM-DD');
            state.toDate = dayjs().endOf('day').format('YYYY-MM-DD');
        },
        clearWeatherInfo(state) {
            state.weatherInfo = null;
            state.weatherInfoError = '';
            state.weatherInfoLoading = false;
        },
        clearGeneralDetails(state) {
            state.generalDetails = {} as GeneralDetails;
        },
        setSelectedCustomerName(state, action: PayloadAction<string>) {
            state.selectedCustomerName = action.payload;
        },
        setSelectedSite(state, action: PayloadAction<string>) {
            state.selectedSite = action.payload;
        },
        setSelectedTimezone(state, action: PayloadAction<string>) {
            state.selectedTimezone = action.payload;
        },
        setSelectedTimezoneAbb(state, action: PayloadAction<string>) {
            state.selectedTimezoneAbb = action.payload;
        },
        setIconTheme(state, action: PayloadAction<number>) {
            state.theme = action.payload;
        },
        setFromDate(state, action: PayloadAction<string>) {
            state.fromDate = action.payload;
        },
        setToDate(state, action: PayloadAction<string>) {
            state.toDate = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchUserInfo.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchUserInfo.fulfilled, (state, action: PayloadAction<UserInfo>) => {
                state.loading = false;
                state.userInfo = action.payload;
            })
            .addCase(fetchUserInfo.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string;
            })
            .addCase(fetchWeatherData.pending, (state) => {
                state.weatherInfoLoading = true;
                state.weatherInfoError = '';
            })
            .addCase(fetchWeatherData.fulfilled, (state, action) => {
                state.weatherInfoLoading = false;
                state.weatherInfo = action.payload;
            })
            .addCase(fetchWeatherData.rejected, (state, action) => {
                state.weatherInfoLoading = false;
                state.weatherInfoError = action.payload as string;
            })
            .addCase(logoutUser.pending, (state) => {
                state.loadingLogout = true;
                state.errorLogout = null;
            })
            .addCase(logoutUser.fulfilled, (state) => {
                state.loadingLogout = true;
                state.loggedOut = true;
                // Optionally, you can reset the user-related state here
            })
            .addCase(logoutUser.rejected, (state, action) => {
                state.loadingLogout = false;
                state.errorLogout = action.payload as string || 'Logout failed';
            })
            .addCase(fetchAllPermittedCustomers.pending, (state) => {
                state.permittedCustomersLoading = true;
                // state.permittedCustomersError = null;
            })
            .addCase(fetchAllPermittedCustomers.fulfilled, (state, action: any) => {
                state.permittedCustomersLoading = false;
                if (Array.isArray(action.payload)) {
                    state.permittedCustomers = action.payload;
                }
                else
                    state.permittedCustomers = [action.payload];
                state.permittedCustomersError = '';
            })
            .addCase(fetchAllPermittedCustomers.rejected, (state, action) => {
                state.permittedCustomersLoading = false;
                state.permittedCustomersError = action.payload as string;
            })
            .addCase(fetchAllCustomerSites.pending, (state) => {
                state.customerSitesLoading = true;
                state.customerSitesError = null;
            })
            .addCase(fetchAllCustomerSites.fulfilled, (state, action: PayloadAction<CustomerSite[]>) => {
                state.customerSitesLoading = false;
                state.customerSites = action.payload;
            })
            .addCase(fetchAllCustomerSites.rejected, (state, action) => {
                state.customerSitesError = action.payload as string;
                if (action.payload && (action.payload === 'Request canceled')) {
                    state.customerSitesLoading = true;
                }
                else state.customerSitesLoading = false;
            })
            .addCase(fetchGeneralDetails.pending, (state, action: any) => {
                state.generalDetailsLoading = true;
                state.generalDetails = {} as GeneralDetails;
                state.generalDetailsError = null;
            })
            .addCase(fetchGeneralDetails.fulfilled, (state, action: PayloadAction<GeneralDetails>) => {
                state.generalDetailsLoading = false;
                state.generalDetails = action.payload;
            })
            .addCase(fetchGeneralDetails.rejected, (state, action) => {
                state.generalDetailsLoading = false;
                state.generalDetailsError = action.payload as string;
            });;
    },
});

export const { clearUserInfo, clearWeatherInfo, clearGeneralDetails, setSelectedCustomerName, setSelectedSite, setIconTheme, setFromDate, setToDate, setSelectedTimezoneAbb, setSelectedTimezone } = userInfoSlice.actions;
export default userInfoSlice.reducer;
