import React, { useEffect } from 'react';
import { Card, Empty, Modal, Space, Spin, Switch, Tooltip } from 'antd';
import { StatisticCard } from '@ant-design/pro-components';
import { Bar, Line, Pie } from 'react-chartjs-2';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { FullscreenOutlined } from '@ant-design/icons';
import duration from 'dayjs/plugin/duration';
import { Chart, CategoryScale, LinearScale, LogarithmicScale, BarController, BarElement, PointElement, LineController, ArcElement, Title, Tooltip as ChartTooltip, Legend } from 'chart.js'; // Import the necessary components
import { RootState } from '../../store';
import { SessionData } from '../../redux/types/chargerTypes';

// Register components for Chart.js
Chart.register(
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  BarController,
  BarElement,
  PointElement,
  LineController,
  ArcElement,
  Title,
  ChartTooltip,
  Legend
);

dayjs.extend(duration);

interface StaticCardProps {
  cardTitle: string;
  style?: React.CSSProperties;
}

interface ChartData {
  energyConsumed: number;
  fullTimeRanges: string[];
}

interface ChartContainer {
  [key: string]: ChartData;
}

const StaticCard: React.FC<StaticCardProps> = ({ cardTitle, style }) => {
  // const sessionData = [] as SessionData[];
  const { sessionData, sessionTabIndex, isLoadingSessionData: sessionDataLoading } = useSelector(
    (state: RootState) => state.chargers
  );
  const [logView, setLogView] = React.useState<boolean>(true);
  const [modalOpen, setModalOpen] = React.useState<boolean>(false);
  const cardStyle: React.CSSProperties = {
    width: '100%',
    // height: '180px',
    padding: '5px',
    ...style
  };

  const chartContainerStyle: React.CSSProperties = {
    width: '100%',
    height: '90%',
    padding: '5px',
  };

  const handleCheck = (checked: boolean) => {
    setLogView(checked);
  };

  let containerToShow: ChartContainer = {};

  const processData = (data: SessionData[]) => {
    const result: { [key: string]: any } = {};
    if (sessionTabIndex === '1') {
      data.forEach(session => {
        if (session?.metrics?.chargingStop) {
          const startTime = dayjs(session.metrics.chargingStart).format('HH:mm');
          const endTime = dayjs(session.metrics.chargingStop).format('HH:mm');
          const fullStartTime = dayjs(session.metrics.chargingStart).format('DD-MM-YY HH:mm');
          const fullEndTime = dayjs(session.metrics.chargingStop).format('DD-MM-YY HH:mm');
          const timeRange = `${startTime}-${endTime}`;
          const fullTimeRange = `${fullStartTime} - ${fullEndTime}`;
          const energyConsumedKw = session.metrics.energyConsumed ? session.metrics.energyConsumed / 1000 : 0;

          if (result[timeRange]) {
            (result[timeRange] as ChartData).energyConsumed += energyConsumedKw;
            (result[timeRange] as ChartData).fullTimeRanges.push(fullTimeRange);
          } else {
            result[timeRange] = {
              energyConsumed: energyConsumedKw,
              fullTimeRanges: [fullTimeRange],
            };
          }
        }
      });
    } else if (sessionTabIndex === '2') {
      data.forEach(session => {
        if (session?.metrics?.chargingStop) {
          const fullDate = dayjs(session.metrics.chargingStop).format('YYYY-MM-DD');
          const energyConsumedKw = session.metrics.energyConsumed ? session.metrics.energyConsumed / 1000 : 0;
          const startTime = dayjs(session.metrics.chargingStart).format('HH:mm');
          const endTime = dayjs(session.metrics.chargingStop).format('HH:mm');
          const fullStartTime = dayjs(session.metrics.chargingStart).format('DD-MM-YY HH:mm');
          const fullEndTime = dayjs(session.metrics.chargingStop).format('DD-MM-YY HH:mm');
          const timeRange = `${startTime}-${endTime}`;
          const fullTimeRange = `${fullStartTime} - ${fullEndTime}`;

          if (containerToShow[timeRange]) {
            containerToShow[timeRange].energyConsumed += energyConsumedKw;
            containerToShow[timeRange].fullTimeRanges.push(fullTimeRange);
          } else {
            containerToShow[timeRange] = {
              energyConsumed: energyConsumedKw,
              fullTimeRanges: [fullTimeRange],
            };
          }

          if (result[fullDate]) {
            result[fullDate] += energyConsumedKw;
          } else {
            result[fullDate] = energyConsumedKw;
          }
        }
      });
    }
    return result;
  };

  const chartData = processData(sessionData);

  const barChartData = {
    labels: Object.keys(chartData),
    datasets: [
      {
        label: sessionTabIndex === '2' ? 'Total Kw' : 'kW',
        data: sessionTabIndex === '2' ? Object.values(chartData) : Object.values(chartData).map(item => (item as ChartData).energyConsumed),
        backgroundColor: '#47a6ff',
        borderColor: '#0865e7',
        borderWidth: 0.5,
      },
    ],
  };

  const pieChartData = {
    labels: ['Fuel Level', 'Remaining'],
    datasets: [{
      data: [35, 65],
      backgroundColor: ['#69b1ff', '#ffffff'],
      borderColor: 'rgb(128,128,128)',
      borderWidth: 0.2,
    }]
  };

  const pieChartOptions = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (context: any) {
            let label = context.label || '';
            if (label) {
              label += ': ';
            }
            if (context.parsed) {
              label += context.parsed + '%';
            }
            return label;
          }
        }
      }
    },
    layout: {
      padding: {
        left: 20,
        right: 20,
        top: 5,
        bottom: 5,
      },
    },
    elements: {
      arc: {
        borderWidth: 0,
      }
    },
    radius: 44,
  };


  // CSS for the tooltip
  const tooltipStyles = `
        #chartjs-tooltip {
            opacity: 0;
            transition: opacity 0.2s ease;
            pointer-events: none;
        }
    
        #chartjs-tooltip .tooltip-table {
            width: 100%;
            border-collapse: collapse;
            border-radius: 8px;
            overflow: hidden;
        }
    
        #chartjs-tooltip .tooltip-table th,
        #chartjs-tooltip .tooltip-table td {
            padding: 8px 12px;
            border: 1px solid #ddd;
            text-align: left;
            background: white;
        }
    
        #chartjs-tooltip .tooltip-table th {
            background-color: #f5f5f5;
        }
    
        #chartjs-tooltip .tooltip-table tr:last-child td {
            border-bottom: none;
        }
    
        #chartjs-tooltip .tooltip-table th:first-child,
        #chartjs-tooltip .tooltip-table td:first-child {
            border-left: none;
        }
    
        #chartjs-tooltip .tooltip-table th:last-child,
        #chartjs-tooltip .tooltip-table td:last-child {
            border-right: none;
        }
    
        #chartjs-tooltip .total-row {
            font-weight: bold;
            background-color: #f5f5f5;
            border-top: 2px solid #ddd;
        }
    `;


  useEffect(() => {
    const styleEl = document.createElement('style');
    styleEl.innerHTML = tooltipStyles;
    document.head.appendChild(styleEl);

    return () => {
      document.head.removeChild(styleEl);
    };
  }, []);

  const spinnerOverlayStyle: React.CSSProperties = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'rgba(255, 255, 255, 0.5)',
    zIndex: 1
  };

  const chartContainerWrapperStyle: React.CSSProperties = {
    position: 'relative',
    minHeight: '200px'
  };

  const handleCardClick = () => {
    setModalOpen(true);
  };

  const options: any = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: sessionTabIndex === '2' ? false : true, // Disable default tooltip
        external: function (context: any) {
          if (sessionTabIndex === '2') {
            let tooltipEl = document.getElementById('chartjs-tooltip');

            if (!tooltipEl) {
              tooltipEl = document.createElement('div');
              tooltipEl.id = 'chartjs-tooltip';
              tooltipEl.style.background = 'rgba(255, 255, 255, 0.8)';
              tooltipEl.style.borderRadius = '8px';
              tooltipEl.style.boxShadow = '0 2px 4px rgba(0, 0, 0, 0.2)';
              tooltipEl.style.padding = '10px';
              tooltipEl.style.position = 'absolute';
              tooltipEl.style.transform = 'translate(-50%, 0)';
              tooltipEl.style.transition = 'opacity 0.2s ease';
              tooltipEl.style.pointerEvents = 'none';
              tooltipEl.style.zIndex = '100';
              document.body.appendChild(tooltipEl);
            }

            const tooltipModel = context.tooltip;
            if (tooltipModel.opacity === 0) {
              tooltipEl.style.opacity = '0';
              return;
            }

            tooltipEl.classList.remove('above', 'below', 'no-transform');
            if (tooltipModel.yAlign) {
              tooltipEl.classList.add(tooltipModel.yAlign);
            } else {
              tooltipEl.classList.add('no-transform');
            }

            if (tooltipModel.body) {
              const dayLabel = tooltipModel.dataPoints[0].label;

              const sessionsForDay = Object.entries(containerToShow)
                .filter(([range, details]) =>
                  details.fullTimeRanges.some(fullRange => {
                    const datePart = fullRange.split(' ')[3];
                    const formattedDate = dayjs(datePart, 'DD-MM-YY').format('YYYY-MM-DD');
                    return formattedDate === dayLabel;
                  })
                );

              const totalKw = sessionsForDay
                .reduce((total, [, details]) => total + details.energyConsumed, 0)
                .toFixed(2);

              const tableRows = sessionsForDay
                .map(([range, details]) => {
                  const energyLine = `${details.energyConsumed.toFixed(2)} kW`;
                  const timeRanges = details.fullTimeRanges.map(fullRange => {
                    const [start, end] = fullRange.split(' - ');
                    const startDate = dayjs(start, 'DD-MM-YY HH:mm');
                    const endDate = dayjs(end, 'DD-MM-YY HH:mm');
                    const sameDay = startDate.isSame(endDate, 'day');

                    return sameDay
                      ? `${startDate.format('HH:mm')} - ${endDate.format('HH:mm')}`
                      : fullRange;
                  }).join('<br>');

                  return `<tr><td>${timeRanges}</td><td>${energyLine}</td></tr>`;
                })
                .join('');

              const tableHtml = `
                            <table class="tooltip-table">
                                <thead>
                                    <tr><th>Time Period</th><th>Energy Consumed</th></tr>
                                </thead>
                                <tbody>
                                    ${tableRows}
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td colspan="2" class="total-row">Total: ${totalKw} kW</td>
                                    </tr>
                                </tfoot>
                            </table>`;

              tooltipEl.innerHTML = tableHtml;
            }

            const position = context.chart.canvas.getBoundingClientRect();

            tooltipEl.style.opacity = '1';
            tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
            tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
            tooltipEl.style.font = tooltipModel.options.bodyFont.string;
          }
        }
      },
    },
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
      },
    },
    scales: {
      x: {
        position: 'bottom',
        grid: {
          display: true,
        },
      },
      y: {
        display: true,
        grid: {
          display: true,
        },
        type: !modalOpen ? 'linear' : logView ? 'logarithmic' : 'linear',
        beginAtZero: true,
        ticks: {
          callback: function (value: any, index: any, values: any) {
            return Number(value.toString()); // Customize tick formatting
          }
        }
      },
    },
  };

  return (
    <Card styles={{ header: { borderColor: 'transparent' } }}
      extra={<Tooltip color='blue' title="Expand" className='clickableOpacity'><FullscreenOutlined onClick={() => handleCardClick()} /></Tooltip>}
      size="small"
      title={cardTitle}
      style={cardStyle}
      className="cardCharger"
      hoverable>
      <div style={{ ...chartContainerStyle, ...chartContainerWrapperStyle }}>
        {sessionTabIndex !== '3' && sessionDataLoading && (
          <div style={spinnerOverlayStyle}>
            <Spin size="large" />
          </div>
        )}
        {cardTitle === 'Hours in use' || cardTitle === 'Power in Kw' ? (
          barChartData.datasets[0].data.length > 0 ? (
            <div className="chart-container">
              <Bar options={options} data={barChartData} style={{ 'width': '100%' }} />
            </div>
          ) : (
            <Empty description="No data available to display for the selected filter" />
          )
        ) : cardTitle === 'Fuel consumption' ? (
          <Pie data={pieChartData} options={pieChartOptions} />
        ) : (
          <Line options={options} data={barChartData} style={{ 'width': '100%' }} />
        )}
      </div>
      <Modal open={modalOpen} mask={true} zIndex={1} footer={null} width={'90%'} onCancel={() => setModalOpen(false)}>
        <StatisticCard
          size="small"
          bordered={false}
          title={cardTitle}
          style={{ height: '70vh' }}
          // style={cardStyle}
          // hoverable
          onClick={() => handleCardClick()}
        >
          <div style={{ ...chartContainerStyle, ...chartContainerWrapperStyle }}>
            {sessionTabIndex !== '3' && sessionDataLoading && (
              <div style={spinnerOverlayStyle}>
                <Spin size="large" />
              </div>
            )}
            {cardTitle === 'Hours in use' || cardTitle === 'Power in Kw' ? (
              barChartData.datasets[0].data.length > 0 ? (
                <div style={{ height: '100%' }}>
                  <div style={{ display: 'flex', gap: 10, justifyContent: 'end' }}><b>Linear View</b><Switch checked={logView} onChange={handleCheck} /><b>Logarithmic View</b></div>
                  <Bar data={barChartData} options={options} style={{ 'width': '100%' }} />
                </div>
              ) : (
                <Empty description="No data available to display for the selected filter" />
              )
            ) : cardTitle === 'Fuel consumption' ? (
              <Pie data={pieChartData} options={pieChartOptions} />
            ) : (
              <Line data={barChartData} options={options} />
            )}
          </div>
        </StatisticCard>
      </Modal>
    </Card>
  );
};

export default StaticCard;
