import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Card, Col, DatePicker, Row, Spin, Tabs, Typography } from "antd";
import { RootState } from '../../rootreducer';
import { useDispatch, useSelector } from 'react-redux';
import _, { toNumber } from 'lodash';
import NoChargerImg from "../../assets/No_Charger_Img.jpg";
import OrangeCircle from "../../assets/orange-circle.png";
import RedCircle from "../../assets/red-circle.png";
import GreenCircle from "../../assets/transparent-green-circle.png";
import classNames from 'classnames';
import { CHARGERS } from '../../utils/constants';
import StaticCard from '../graphs/StaticCard';
import { AppDispatch } from '../../store';
import { fetchAllSessionDataForChargers } from '../../redux/services/chargerSevice';
import dayjs from 'dayjs';
import { setSessionTabIndex } from '../../redux/slices/chargerSlice';
import TodaySessionJsonDataDisplay from './TodaySessionJsonDataDisplay';
import PastSessionJsonDataDisplay from './PastSessionJsonDataDisplay';
import ErrorJsonDataDisplay from './ErrorJsonDataDisplay';



const IndividualCharger = () => {
    const { chargerId }: any = useParams();
    const dispatch = useDispatch<AppDispatch>();
    const [fromDate, setFromDate] = useState(dayjs().startOf('day'));
    const [toDate, setToDate] = useState(dayjs().endOf('day'));
    const [selectedChargerDetails, setSelectedChargerDetails] = useState<any>([]);
    const [pastSessionStart, setPastSessonStart] = useState(dayjs().subtract(7, 'day').startOf('day'));
    const [pastSessionEnd, setPastSessionEnd] = useState(dayjs().subtract(1, 'day').endOf('day'));
    const { detailedChargerDataForSite, loadingDetailedChargerDataForSite } = useSelector((state: RootState) => state.dashboard);

    const { sessionTabIndex, isLoadingSessionData: sessionDataLoading } = useSelector(
        (state: RootState) => state.chargers
    );
    const { selectedCustomerName, selectedSite } = useSelector((state: RootState) => state.userInfo);
    const navigate = useNavigate();

    useEffect(() => {
        if (detailedChargerDataForSite.all_connected_chargers.length > 0 && !loadingDetailedChargerDataForSite) {
            const data = detailedChargerDataForSite.all_connected_chargers.filter((data) => {
                return data.id === toNumber(chargerId)
            })
            setSelectedChargerDetails(data);
            if (sessionTabIndex == '1') {
                dispatch(setSessionTabIndex('1'))
                dispatch(fetchAllSessionDataForChargers({ fromDate: fromDate?.toISOString(), toDate: toDate?.toISOString(), chargerStationId: chargerId, selectedCustomerName }));
            }
            if (sessionTabIndex == '2') {
                dispatch(setSessionTabIndex('2'))
                dispatch(fetchAllSessionDataForChargers({ fromDate: pastSessionStart?.toISOString(), toDate: pastSessionEnd?.toISOString(), chargerStationId: chargerId, selectedCustomerName }));
            }
            if (sessionTabIndex === '3') {
                dispatch(setSessionTabIndex('3'));
            }
        }
        // else if (!loadingDetailedChargerDataForSite) {
        //     setSelectedChargerDetails([]);
        //     navigate('/chargers');
        // }
    }, [detailedChargerDataForSite, chargerId])

    const handleImageError = (event: any) => {
        event.target.src = NoChargerImg;
    };

    const chargerCurrentStatusApiOrNot = selectedChargerDetails && selectedChargerDetails[0] &&
        selectedChargerDetails[0].api_source && selectedChargerDetails[0].api_source === 'static' ? 'static' : 'api';

    console.log('chargerCurrentStatusApiOrNot', chargerCurrentStatusApiOrNot)
    console.log("detailedChargerDataForSite", detailedChargerDataForSite)

    // const chargerCurrentStatus: any = _.get(detailedChargerDataForSite.connector, `${Number(chargerId)}.connectors[0].status`, chargerCurrentStatusApiOrNot === 'static' ? 'static' : 'available')?.toLocaleLowerCase();
    const chargerCurrentStatus: any = chargerCurrentStatusApiOrNot === 'static' ? 'static' : detailedChargerDataForSite.connector.filter(item => item.charger === Number(chargerId))?.[0]?.connectors?.[0]?.status?.toLocaleLowerCase();
    console.log('chargerCurrentStatus', chargerCurrentStatus)
    const isStatusChargingClassName = classNames({
        "grey-img": chargerCurrentStatus !== "charging",
    });

    const isStatusFaultedClassName = classNames({
        "grey-img": chargerCurrentStatus !== "faulted",
    });

    const isStatusAvailableClassName = classNames({
        "grey-img": chargerCurrentStatus !== "available",
    });

    if (selectedChargerDetails.length === 0) {
        return (
            <Spin spinning={loadingDetailedChargerDataForSite}>
                <Row justify="center">
                    <Col xs={24} sm={22} md={20} lg={18} xl={16} className="loadingCol">
                        <Typography.Title level={2} type="secondary">Loading charger details. Please wait!</Typography.Title>
                    </Col>
                </Row>
            </Spin>
        )
    }

    const { RangePicker } = DatePicker;

    const addFilter = (value: any) => {
        setPastSessonStart(value && value[0] ? value[0] : undefined);
        setPastSessionEnd(value && value[1] ? value[1] : undefined);
    }

    const handleSessionChanges = (key: any) => {
        setPastSessonStart(dayjs().subtract(7, 'day').startOf('day'));
        setPastSessionEnd(dayjs().subtract(1, 'day').endOf('day'));
        if (key == 1) {
            dispatch(setSessionTabIndex('1'))
            dispatch(fetchAllSessionDataForChargers({ fromDate: fromDate?.toISOString(), toDate: toDate?.toISOString(), chargerStationId: chargerId, selectedCustomerName }));
        }
        if (key == 2) {
            dispatch(setSessionTabIndex('2'))
            dispatch(fetchAllSessionDataForChargers({ fromDate: pastSessionStart?.toISOString(), toDate: pastSessionEnd?.toISOString(), chargerStationId: chargerId, selectedCustomerName }));
        }
        if (key === 3) {
            dispatch(setSessionTabIndex('3'));
        }
    };

    const items = [
        {
            key: "1",
            label: "Today's sessions",
            children: <TodaySessionJsonDataDisplay handleSessionChanges={handleSessionChanges} />,
        },
        {
            key: "2",
            label: "Past sessions",
            children: <Card size='small'>
                <div style={{ display: 'flex', alignItems: 'center', gap: '10px', marginBottom: '10px' }}>
                    <RangePicker
                        showTime={{ format: 'HH:mm' }}
                        value={[pastSessionStart, pastSessionEnd]}
                        maxDate={dayjs().endOf('day').subtract(1, 'day')}
                        format="YYYY-MM-DD HH:mm"
                        onChange={(value, dateString) => {
                            addFilter(value)
                        }}
                    />
                    <Button loading={sessionDataLoading} type="primary" onClick={() => dispatch(fetchAllSessionDataForChargers({ fromDate: pastSessionStart?.toISOString(), toDate: pastSessionEnd?.toISOString(), chargerStationId: chargerId, selectedCustomerName }))}>Search</Button>
                </div>
                <PastSessionJsonDataDisplay handleSessionChanges={handleSessionChanges} />
            </Card>,
        },
        {
            key: "3",
            label: "Error",
            children: <ErrorJsonDataDisplay handleSessionChanges={handleSessionChanges} />,
            // children: <div></div>
        },
    ];

    return (
        // <Card className='chargerSection' title={selectedChargerDetails[0]?.charger_title}>
        <div>
            <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                    <Card
                        title={selectedChargerDetails[0]?.charger_title}
                        size="small"
                        className='cardCharger'
                        styles={{ header: { borderColor: 'transparent' } }}
                    >
                        <div style={{ display: 'flex', flexDirection: 'row', gap: '10px', justifyContent: 'space-around' }}>
                            <img
                                src={selectedChargerDetails[0].charger_model.image_src}
                                alt="Charger Vendor Image"
                                style={{ width: '140px', height: '180px' }}
                                onError={handleImageError}
                            />                        <Card size="small" title="Running Status" className="cardUnselected" loading={loadingDetailedChargerDataForSite}>
                                <div className="card-row">
                                    <img
                                        src={chargerCurrentStatus === 'available' ? GreenCircle : OrangeCircle}
                                        className={isStatusAvailableClassName}
                                        alt="status"
                                        width={15}
                                        height={15}
                                    />
                                    <span className="status-name">Available</span>
                                </div>
                                <div className="card-row">
                                    <img
                                        src={OrangeCircle}
                                        className={isStatusChargingClassName}
                                        alt="status"
                                        width={15}
                                        height={15}
                                    />
                                    <span className="status-name">Charging</span>
                                </div>
                                <div className="card-row">
                                    <img
                                        src={chargerCurrentStatus === 'faulted' ? RedCircle : OrangeCircle}
                                        className={isStatusFaultedClassName}
                                        alt="status"
                                        width={15}
                                        height={15}
                                    />
                                    <span className="status-name">Faulted</span>
                                </div>
                                {selectedChargerDetails[0].charger_model.model === 'HCS-60' && (
                                    <>
                                        <Row gutter={[8, 8]}>
                                            <Col span={3} className="circleStyle"></Col>
                                            <Col span={21}>100% Power</Col>
                                        </Row>
                                        <Row gutter={[8, 8]}>
                                            <Col span={3} className="circleStyle"></Col>
                                            <Col span={21}>50% Power</Col>
                                        </Row>
                                        <Row gutter={[8, 8]}>
                                            <Col span={3} className="circleStyle"></Col>
                                            <Col span={21}>25% Power</Col>
                                        </Row>
                                        <Row gutter={[8, 8]}>
                                            <Col span={3} className="circleStyle"></Col>
                                            <Col span={21}>Standby</Col>
                                        </Row>
                                    </>
                                )}
                            </Card>
                        </div>
                    </Card>
                </Col>
                {/* <Col xs={24} sm={24} md={12} lg={7} xl={4}>
                    <Card size="small" title="Running Status" className="cardUnselected" loading={loadingDetailedChargerDataForSite}>
                        
                    </Card>
                </Col> */}
                <Col xs={24} sm={24} md={24} lg={12} xl={16}>
                    <StaticCard cardTitle="Power in Kw" style={{ height: '340px' }} />
                </Col>
            </Row>
            <Row gutter={[16, 16]}>
                <Col xs={24}>
                    <div className='h-18' />
                    <Tabs
                        defaultActiveKey={sessionTabIndex}
                        items={items}
                        style={{ borderRadius: '10px' }}
                        className='chargerTabs'
                        destroyInactiveTabPane={true}
                        onChange={handleSessionChanges}
                    ></Tabs>
                </Col>
                {/* <Col>
                    <Button
                        onClick={() =>
                            navigate(`/${selectedCustomerName}/${selectedSite}/${CHARGERS}`)
                        }
                        type="primary"
                        className="backButton"
                    >
                        Back
                    </Button>
                </Col> */}
            </Row>
        </div>
    )
}

export default IndividualCharger