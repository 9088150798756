import { Avatar, Button, Card, Checkbox, CheckboxOptionType, Col, Drawer, Dropdown, Empty, Input, Layout, Menu, Popover, Row, Select, Space, Spin, Switch, Table, theme, Tooltip, Typography } from "antd";
import './General.css';  // Assuming you add the CSS here
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import LogoSmall from "../../assets/logo/logoSmall.png";
import LogoDark from "../../assets/logo/LogoDarkV2.svg";
import '../dashboard/Dashboard.css';
import MapsGlobal from "../maps/MapsGlobal";
import { BellOutlined, EyeInvisibleOutlined, MenuFoldOutlined, MenuUnfoldOutlined, PoweroffOutlined, SearchOutlined, SolutionOutlined, UserOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { fetchAllCustomerSites, logoutUser } from "../../redux/services/customerService";
import { Link, Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { clearUserInfo, setIconTheme, setSelectedCustomerName, setSelectedSite, setSelectedTimezone } from "../../redux/slices/customerSlice";
import { Content, Header } from "antd/es/layout/layout";
import Sider from "antd/es/layout/Sider";
import { MenuProps } from "antd/lib";
import { CHARGERS, FUEL, GENERAL, POWER_GENERATION, red, blue, yellow, green, grey, faults, alarms } from "../../utils/constants";
import { initiateLogin } from "../../utils/common";
import _ from "lodash";
import hrsSpnt from '../../assets/Dashboard/HoursSpentchargingV2.svg';
import kwhgen from '../../assets/Dashboard/KwhGenV2.svg';
import chgTdy from '../../assets/Dashboard/ChargingSessTdyV2.svg';
import MainIconTemp3 from "./MainIconTemp3";
import { fetchBPLiveDataForCustomer, fetchdetailedSummaryCharger } from "../../redux/services/dashboardService";
import dayjs from "dayjs";
import { TableInterface } from "../../redux/types/dashboardTypes";
import { ColumnsType } from "antd/es/table";
import { clearPowerState, setSelectedGeneratorId, setSelectedSourceId } from "../../redux/slices/powerSlice";
import { clearChargerData } from "../../redux/slices/chargerSlice";
import { clearDashboardData } from "../../redux/slices/dashboardSlice";
import globalDashboard from '../../assets/Dashboard/database-01-stroke-rounded 1.png';
import globalDashboardGreen from '../../assets/sideNav/selected/Global.svg';
import Maps from '../../assets/sideNav/Maps.svg';
import MapsGreen from '../../assets/sideNav/selected/Maps.svg';
import duration from 'dayjs/plugin/duration';
import timezone from 'dayjs/plugin/timezone';
// Extend Day.js with the duration plugin
dayjs.extend(duration);
dayjs.extend(timezone);

interface ColorCircleProps {
    color: string;  // The color for the circle background
    value: number;  // The number or text to display in the center
}

const GlobalDashboard3 = () => {
    const { permittedCustomers, permittedCustomersLoading, selectedCustomerName, customerSites, customerSitesLoading, userInfo, theme: iconThemeFromRedux, selectedTimezone, selectedTimezoneAbb, selectedSite } = useSelector((state: RootState) => state.userInfo);
    const { detailedChargerData, loadingDetailedChargerData, detailedGeneratorData, loadingGenDetails } = useSelector((state: RootState) => state.dashboard);
    const loadingLength = Object.keys(detailedChargerData).length > 0 && Object.keys(detailedGeneratorData).length > 0;
    const dispatch = useDispatch<AppDispatch>();
    const [collapsed, setCollapsed] = useState<boolean>(false);
    const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth < 768);
    const { customer, site } = useParams();
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [filteredData, setFilteredData] = useState<TableInterface[]>([]);
    const [generatorData, setGeneratorData] = useState<TableInterface[]>([]);
    const [showMaps, setShowMaps] = useState<boolean>(false);
    const [open, setOpen] = useState(false);
    const [openModal, setOpenModal] = useState<boolean>(false);
    const navigate = useNavigate();
    const [iconTheme, setIconThemeLocal] = useState<number>(iconThemeFromRedux);

    useEffect(() => {
        setIconThemeLocal(iconThemeFromRedux);
    }, [iconThemeFromRedux])

    const responsiveColCards = { xxl: 12, xl: 12, lg: 12, md: 24, sm: 24, xs: 24 };

    const responsiveColCardsInner = { xxl: 12, xl: 12, lg: 12, md: 12, sm: 24, xs: 24 };

    const handleOpenChange = (newOpen: boolean) => {
        setOpen(newOpen);
    };

    // useEffect(() => {
    //     if (selectedSite === '') {
    //         stopPollingBPLiveDataForSite();
    //         stopPollingChargerSummaryForSite();
    //     }
    // }, [selectedSite])

    useEffect(() => {
        if (customerSites.length > 0) {
            const newData = customerSites.map((site) => ({
                ...site,
                chargerStatus: detailedChargerData?.connector?.filter(item => item.siteId === site.id) || [],
                generatorStatus: detailedGeneratorData?.filter(item => item.site_id === site.id) || [],
                chargerList: detailedChargerData?.all_connected_chargers?.filter(item => item.site_id === site.id) || [],
            }));

            setFilteredData(newData);
        } else {
            setFilteredData([]);
        }
    }, [detailedGeneratorData, detailedChargerData, customerSites]);



    useEffect(() => {
        if (searchQuery.trim() === '' && customerSites.length > 0 && filteredData.length > 0) {
            setGeneratorData(filteredData); // Show all if searchQuery is empty
        } else if (searchQuery.trim() !== '' && customerSites.length > 0 && filteredData.length > 0) {
            const lowercasedQuery = searchQuery.toLowerCase();

            const filtered = filteredData.filter((site) => {
                const siteName = customerSites.find((customerSite) => site.name === customerSite.name)?.name;
                // Adjust this to include the fields you want to search through
                return (
                    siteName?.toLowerCase().includes(lowercasedQuery)
                );
            });

            setGeneratorData(filtered);
        }
    }, [searchQuery, filteredData, customerSites]);

    const handleResize = () => {
        setIsMobile(window.innerWidth < 768);
    };

    const signOut = () => {

        dispatch(logoutUser()).then(() => {
            // Redirect to login page or initiate login
            initiateLogin();
        });
    };

    useEffect(() => {
        dispatch(clearPowerState());
        dispatch(clearChargerData());
        dispatch(setSelectedSite(''));
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleNavigate = (siteName: string) => {
        // dispatch(fetchAllCustomerSites(selectedCustomerName)).then(() => {
        dispatch(setSelectedSite(siteName))
        // });

        navigate(`/${selectedCustomerName}/${siteName}/${GENERAL}`);
    };

    const [siteKwhDetails, setSiteKwhDetails] = useState([{ kWh: 0, site_id: '' }]);
    const [totalKwh, setTotalKwh] = useState(0);

    // Function to calculate KWH sums
    const calculateKwhSums = () => {
        let totalKWH = 0;
        const siteKwhArray: any = [];

        if (!loadingGenDetails && detailedGeneratorData && detailedGeneratorData.length > 0) {
            const siteKwhMap: any = {};

            detailedGeneratorData.forEach(item => {
                const siteId = item.site_id;

                item.equipment_data.forEach(item2 => {
                    item2.data.forEach(item3 => {
                        if (item3.value_name === 'kWh') {
                            const kwhValue = Number(item3.value < 0 ? 0 : item3.value);

                            // Accumulate the total KWH
                            totalKWH += kwhValue;

                            // Accumulate KWH per site_id
                            if (!siteKwhMap[siteId]) {
                                siteKwhMap[siteId] = 0;
                            }
                            siteKwhMap[siteId] += kwhValue;
                        }
                    });
                });
            });

            // Convert siteKwhMap to an array of objects with site_id and kWh
            for (const [siteId, kwhSum] of Object.entries(siteKwhMap)) {
                siteKwhArray.push({ site_id: siteId, kWh: _.round(kwhSum as number, 2) });
            }
        }

        // Update state with the calculated values
        setTotalKwh(_.round(totalKWH, 2));
        setSiteKwhDetails(siteKwhArray);
    };

    // Recalculate KWH sums whenever `detailedGeneratorData` or `loadingGenDetails` changes
    useEffect(() => {
        calculateKwhSums();
    }, [detailedGeneratorData, loadingGenDetails]);

    const getSiteNamesWithKwh = () => {
        // Prepare the data for the table
        const data = siteKwhDetails.map(site => {
            const matchingSite = customerSites.find(item => item.id.toString() === site.site_id);
            return {
                key: site.site_id,
                siteName: matchingSite ? matchingSite.name : 'Unknown Site',
                kWh: site.kWh
            };
        });

        // Define the table columns
        const columns = [
            {
                title: 'Site Name',
                dataIndex: 'siteName',
                key: 'siteName',
            },
            {
                title: 'kWh',
                dataIndex: 'kWh',
                key: 'kWh',
            },
        ];

        // Return the table
        return (
            <Table
                columns={columns}
                dataSource={data}
                pagination={false} // Disable pagination for tooltip content
                size="small" // Use a compact table
                bordered // Optional: Adds border to the table
            />
        );
    };

    const getChargingSessionsPerWeek = () => {
        if (!loadingDetailedChargerData && detailedChargerData.session && detailedChargerData.session.length > 0) {
            return detailedChargerData.session.filter(item => item.status === 'Ended').length
        }
        else return '0';
    }

    const getChargingSessionsPerDay = (id: any) => {
        if (!loadingDetailedChargerData && detailedChargerData.session && detailedChargerData.session.length > 0) {
            return detailedChargerData.session.filter(item =>
                item.status === 'Ended' &&
                dayjs(item.metrics.chargingStop).isSame(dayjs(), 'day') && item.charger.site === id
            ).length;
        }
        else return 0;
    }

    const getHoursSpentChargingWeek = () => {
        // Ensure data exists and is in the expected format
        if (!loadingDetailedChargerData && detailedChargerData?.session?.length > 0) {
            // Filter sessions where status is 'Ended'
            const endedStats = detailedChargerData.session.filter(
                (item) => item.status === 'Ended'
            );

            // Accumulate the total seconds
            const totalSeconds = endedStats.reduce((acc, item) => {
                // Ensure metrics.energyPeriod is a valid number
                const energyPeriod = Number(item.metrics?.energyPeriod) || 0;
                return acc + energyPeriod;
            }, 0);

            // Handle potential edge cases
            if (isNaN(totalSeconds) || totalSeconds <= 0) {
                return '0';
            }

            // Calculate total hours using dayjs
            const duration = dayjs.duration(totalSeconds, 'seconds');
            const hours = duration.asHours();

            // Return rounded result to 3 decimal places
            return _.round(hours, 3);
        }

        // Default return value for invalid or missing data
        return '0';
    };

    const ColorCircle: React.FC<ColorCircleProps> = ({ color, value }) => {
        return (
            <svg width="35" height="35" viewBox="0 0 35 35" xmlns="http://www.w3.org/2000/svg">
                {/* Circle with dynamic color */}
                <circle cx="17.5" cy="17.5" r="17.5" fill={color} />
                {/* Number/Text in the center */}
                <text
                    x="50%"
                    y="50%"
                    dominantBaseline="central"
                    textAnchor="middle"
                    pointerEvents={'all'}
                    fill="white"
                    fontSize="16"
                    fontWeight={400}
                    fontFamily="Arial, sans-serif"
                    dy="0.05em"
                    dx={value > 99 ? '-0.1em' : ''}
                >
                    {value === -123 ? '' : value < 0 ? 'N/A' : value}
                </text>
            </svg>
        );
    };

    const columns: ColumnsType<TableInterface> = [
        {
            title: 'TITLE',
            dataIndex: 'location',
            key: '0',
            render: (text: any, record) => <a onClick={() => handleNavigate(record.name)}>{`${record.name}`}</a>,
        },
        {
            title: 'SESSIONS',
            key: '1',
            dataIndex: 'csToday',
            render: (text, record) => <Link to={`/${selectedCustomerName}/${record.name}/${CHARGERS}`}>{iconTheme === 0 ? <ColorCircle color={grey} value={record.id ? getChargingSessionsPerDay(record.id) : 0} /> : record.id ? getChargingSessionsPerDay(record.id) < 0 ? '0' : getChargingSessionsPerDay(record.id) : 0}</Link>
        },
        {
            title: 'FUEL (%)',
            key: '2',
            dataIndex: 'fuel',
            render: (text: any, record) => {
                return (
                    <Space size="small">
                        {record.generatorStatus.map((gen, key) => {
                            let sumFuel = 0;
                            let timeStamp = null;
                            // let notliveData = false;
                            gen.equipment_data.forEach((items) => {
                                const genName = items.data[0].equipment_name;
                                // notliveData = items?.LiveStatus !== 'live';

                                if (['EV Charger', 'EV_Charger'].includes(genName) ||
                                    (items.data[0].source_id !== 'BCH-Fuel' && selectedCustomerName === 'bchydro')) return;

                                const fuelLevelRegex = /Fuel Level(\s*\(.*\))?/i;


                                // Simplified fuelValue calculation
                                const fuelValue: any = items.data.find((item) =>
                                    (fuelLevelRegex.test(item.value_name) && item.source_id === 'BCH-Fuel') ||
                                    (fuelLevelRegex.test(item.value_name) && item.source_id !== 'BCH-Fuel')
                                )?.value || 0;

                                timeStamp = items.data.find((item) =>
                                    (fuelLevelRegex.test(item.value_name) && item.source_id === 'BCH-Fuel') ||
                                    (fuelLevelRegex.test(item.value_name) && item.source_id !== 'BCH-Fuel')
                                )?.value_datetime || null;
                                // Parse fuelValue to ensure it's a number
                                sumFuel += parseFloat(fuelValue) || 0;
                            });

                            if (sumFuel === 0) return iconTheme === 0 ? <ColorCircle color={grey} value={-1} /> : <div key={key}>N/A</div>;

                            timeStamp = timeStamp ? selectedTimezone === 'UTC' ? dayjs.utc(timeStamp).format('YYYY-MM-DD HH:mm:ss') : dayjs(timeStamp).tz(selectedTimezone).format('YYYY-MM-DD HH:mm:ss') : null;

                            const tableData = [
                                {
                                    key: key,
                                    lastUpdated: timeStamp,
                                },
                            ];

                            const columns = [
                                { title: 'Last Updated ' + `(${selectedTimezoneAbb})`, dataIndex: 'lastUpdated', key: 'lastUpdated' },
                            ];

                            return (
                                <div key={key}>
                                    {iconTheme === 0 ?
                                        <div key={gen.site_id} style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                                            <Link
                                                to={`/${selectedCustomerName}/${record.name}/${FUEL}/${gen.equipment_data[0].equipment_id}`}
                                            >
                                                <Tooltip color="white" overlayInnerStyle={{ padding: 0 }}
                                                    title={<Table
                                                        columns={columns}
                                                        dataSource={tableData}
                                                        pagination={false}
                                                        size="small"
                                                        bordered
                                                    />} > <ColorCircle color={sumFuel < 25 ? red : sumFuel <= 75 ? yellow : green} value={_.round(sumFuel < 0 ? 0 : sumFuel, 0)} /> </Tooltip>
                                            </Link>
                                        </div> :
                                        <Tooltip color="white" overlayInnerStyle={{ padding: 0 }}
                                            title={<Table
                                                columns={columns}
                                                dataSource={tableData}
                                                pagination={false}
                                                size="small"
                                                bordered
                                            />} >
                                            <div key={gen.site_id} style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                                                {sumFuel < 25 ? <MainIconTemp3 fillColor={red} /> :
                                                    sumFuel <= 75 ? <MainIconTemp3 fillColor={yellow} /> :
                                                        <MainIconTemp3 fillColor={green} />}
                                                <Link to={`/${selectedCustomerName}/${record.name}/${FUEL}/${gen.equipment_data[0].equipment_id}`}>{_.round(sumFuel, 2)}</Link>
                                            </div>
                                        </Tooltip>
                                    }
                                </div>
                            );
                        })}
                    </Space>
                );
            },
        },

        {
            title: 'GENERATORS',
            key: '3',
            dataIndex: 'generators',
            render: (text: any, record) => {
                const hasGenerators = record.generatorStatus?.some((gen) =>
                    gen.equipment_data?.some((items) => {
                        const genName = items?.data?.[0]?.equipment_name;
                        return !['EV Charger', 'EV_Charger'].includes(genName) && items?.data?.[0]?.source_id !== 'BCH-Fuel';
                    })
                );

                if (!hasGenerators) {
                    return iconTheme === 0 ? <ColorCircle color={grey} value={-1} /> : <div key={record.id}>N/A</div>;
                }

                let displayIndex = 1;

                return (
                    <Space size="small">
                        {record.generatorStatus?.map((gen, key) =>
                            gen.equipment_data?.map((items) => {
                                const genName = items?.data?.[0]?.equipment_name;
                                if (!genName || ['EV Charger', 'EV_Charger'].includes(genName) || items?.data?.[0]?.source_id === 'BCH-Fuel') {
                                    return null;
                                }

                                const notliveData = items?.LiveStatus !== 'live';

                                const runningStatus = items?.data?.find((item: any) => item.value_name === 'Running')?.value === 1;

                                const faultStatuses = items?.data
                                    ?.filter(item => faults.includes(item.value_name) && (item.value === 1))
                                    ?.map(item => item.value_name)
                                    || [];

                                const alarmStatuses = items?.data
                                    ?.filter((item) => alarms.includes(item.value_name) && (item.value === 1))
                                    ?.map((item) => item.value_name)
                                    || [];

                                const faultStatusesNegative = items?.data?.some(item =>
                                    item?.value_name && faults.includes(item.value_name) && item.value === -99) || items?.data?.some(item =>
                                        item?.value_name && alarms.includes(item.value_name) && item.value === -99
                                    );

                                const engineHourCheck = items && items?.data && items?.data?.filter(item => item?.value_name === 'Engine Hours') && items?.data?.filter(item => item?.value_name === 'Engine Hours')[0]?.value < 0 || false;

                                const lastUpdated = items?.data?.[0]?.value_datetime ? selectedTimezone === 'UTC' ? dayjs.utc(items?.data?.[0]?.value_datetime).format('YYYY-MM-DD HH:mm:ss') : dayjs(items?.data?.[0]?.value_datetime).tz(selectedTimezone).format('YYYY-MM-DD HH:mm:ss') : null;

                                const color = notliveData || engineHourCheck || faultStatusesNegative
                                    ? grey
                                    : runningStatus
                                        ? blue
                                        : faultStatuses.length > 0
                                            ? red
                                            : alarmStatuses.filter((alarm) => !['Common Alarm', 'Warning Alarm'].includes(alarm)).length > 0
                                                ? yellow
                                                : green;
                                const tableData = [
                                    {
                                        key: displayIndex,
                                        name: genName,
                                        status: notliveData || faultStatusesNegative || engineHourCheck ? 'Unknown/Offline' : runningStatus ? 'Running' : faultStatuses.length > 0 ? 'Fault' : alarmStatuses.filter((alarm) => !['Common Alarm', 'Warning Alarm'].includes(alarm)).length > 0 ? 'Alarm' : 'Able to run',
                                        faults: faultStatuses.length === 0 ? 'None' : faultStatuses.map((fault) => fault).join(', '),
                                        alarms: alarmStatuses.length === 0 ? 'None' : alarmStatuses.map((alarm) => alarm).join(', '),
                                        lastUpdated: lastUpdated
                                    },
                                ];

                                const columns = [
                                    { title: 'Name', dataIndex: 'name', key: 'name' },
                                    { title: 'Status', dataIndex: 'status', key: 'status' },
                                    { title: 'Faults', dataIndex: 'faults', key: 'faults' },
                                    { title: 'Alarms', dataIndex: 'alarms', key: 'alarms' },
                                    { title: 'Last updated ' + `(${selectedTimezoneAbb})`, dataIndex: 'lastUpdated', key: 'lastUpdated' },
                                ];

                                const currentDisplayIndex = displayIndex;
                                displayIndex++;

                                return (
                                    <Popover
                                        key={currentDisplayIndex + key}
                                        content={
                                            <Table
                                                columns={columns}
                                                dataSource={tableData}
                                                pagination={false}
                                                size="small"
                                                bordered
                                            />
                                        }
                                        color="white"
                                        overlayInnerStyle={{ padding: 0 }}
                                    >
                                        <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                                            <Link to={`/${selectedCustomerName}/${record.name}/${POWER_GENERATION}/${items?.equipment_id}`}>
                                                {iconTheme === 0 ? (
                                                    <ColorCircle color={color} value={currentDisplayIndex} />
                                                ) : (
                                                    <div key={currentDisplayIndex} style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                                                        <MainIconTemp3 fillColor={color} /> {genName}
                                                    </div>
                                                )}
                                            </Link>
                                        </div>
                                    </Popover>
                                );
                            })
                        )}
                    </Space>
                );
            },
        }


        ,
        {
            title: 'MAINTENANCE',
            key: '4',
            dataIndex: 'maintenance',
            render: () => {
                // const engineHours = record.generators[0]?.liveValues.find((item: any) => item.value_name === 'Engine Hours')?.value;
                return iconTheme === 0 ? <ColorCircle color={grey} value={-1} /> : 'N/A';
            },
        },
        {
            title: 'CHARGERS',
            key: '5',
            dataIndex: 'chargers',
            render: (text: any, record) => {
                return record.chargerList.length === 0 ? (
                    iconTheme === 0 ? (
                        <ColorCircle color={grey} value={-1} />
                    ) : (
                        <div key={record.id}>N/A</div>
                    )
                ) : (
                    <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                        {record.chargerList.map((charger, index: number) => {
                            // Find the matching status from chargerStatus for the current charger
                            const matchingStatus = record.chargerStatus.find(
                                (status) => status.charger === charger.id
                            );

                            const connectorStatus = matchingStatus?.connectors?.[0]?.status || '';
                            const staticCharger = charger.api_source === 'static';

                            // Prepare the data for the tooltip table
                            const dataSource = [
                                {
                                    key: '1',
                                    name: charger.charger_title,
                                    status: staticCharger
                                        ? 'Static Charger'
                                        : connectorStatus || 'No Status available/static',
                                },
                            ];

                            // Define columns for the tooltip table
                            const columns = [
                                {
                                    title: 'Name',
                                    dataIndex: 'name',
                                    key: 'name',
                                },
                                {
                                    title: 'Status',
                                    dataIndex: 'status',
                                    key: 'status',
                                },
                            ];

                            return (
                                <Tooltip
                                    title={
                                        <Table
                                            dataSource={dataSource}
                                            columns={columns}
                                            pagination={false}
                                            size="small"
                                            bordered
                                        />
                                    }
                                    color="white"
                                    overlayInnerStyle={{ padding: 0 }}
                                    key={index}
                                >
                                    {iconTheme === 0 ? <Space size="small">
                                        <Link
                                            to={`/${selectedCustomerName}/${record.name}/${CHARGERS}/${charger.id}`}
                                            style={{ marginRight: 5 }}
                                        >
                                            <ColorCircle
                                                value={index + 1}
                                                color={
                                                    connectorStatus === 'Available'
                                                        ? green
                                                        : ['Charging', 'Preparing'].includes(connectorStatus)
                                                            ? yellow
                                                            : ['Faulted', 'Suspended EV'].includes(connectorStatus)
                                                                ? red
                                                                : grey
                                                }
                                            />
                                        </Link>
                                    </Space> :
                                        <Space size="small" key={index}>
                                            <div style={{ display: 'flex', alignItems: 'center', marginRight: 5 }}>
                                                <MainIconTemp3
                                                    key={index + charger.id}
                                                    fillColor={
                                                        connectorStatus === "Available"
                                                            ? green
                                                            : ["Charging", "Preparing"].includes(connectorStatus)
                                                                ? yellow
                                                                : ["Faulted", "Suspended EV"].includes(connectorStatus)
                                                                    ? red
                                                                    : grey
                                                    }
                                                />
                                                {/* Display the charger name below the icon */}
                                                <Link to={`/${selectedCustomerName}/${record.name}/${CHARGERS}/${charger.id}`} style={{ marginLeft: 5 }}>
                                                    {charger.charger_title}
                                                </Link>
                                            </div>
                                        </Space>}
                                </Tooltip>
                            );
                        })}
                    </div>
                );
            },
        }
        ,
        {
            title: 'TEMP',
            key: '6',
            dataIndex: 'temp',
            render: () => {
                // const engineHours = record.generators[0]?.liveValues.find((item: any) => item.value_name === 'Engine Hours')?.value;
                return iconTheme === 0 ? <ColorCircle color={grey} value={-1} /> : 'N/A';
            },

        },
        {
            title: 'BATTERIES',
            key: '7',
            dataIndex: 'batteries',
            render: (text: any, record) => {
                if (!record.generatorStatus || record.generatorStatus.length === 0) {
                    return iconTheme === 0 ? (
                        <ColorCircle color={grey} value={-1} />
                    ) : (
                        <div key={record.id}>N/A</div>
                    );
                }

                const hasBatteryData = record.generatorStatus.some((gen) =>
                    gen.equipment_data?.some((items) =>
                        items.data?.some((item: any) => item.value_name === 'Battery Voltage')
                    )
                );

                if (!hasBatteryData) {
                    return iconTheme === 0 ? (
                        <ColorCircle color={grey} value={-1} />
                    ) : (
                        <div key={record.id}>N/A</div>
                    );
                }

                let displayIndex = 1;

                return (
                    <Space size="small">
                        {record.generatorStatus.map((gen, genIndex) =>
                            gen.equipment_data.map((items, equipIndex) => {
                                const batteryData = items.data.find(
                                    (item) => item.value_name === 'Battery Voltage'
                                );
                                const generatorBattery = batteryData?.value ?? 0;
                                const genName = items.data[0]?.equipment_name;
                                const lastUpdated = items.data[0]?.value_datetime ? selectedTimezone === 'UTC' ? dayjs.utc(items.data[0]?.value_datetime).format('YYYY-MM-DD HH:mm:ss') : dayjs(items.data[0]?.value_datetime).tz(selectedTimezone).format('YYYY-MM-DD HH:mm:ss') : '';
                                const notliveData = items?.LiveStatus !== 'live';
                                if (
                                    ['EV Charger', 'EV_Charger'].includes(genName) ||
                                    items.data[0]?.source_id === 'BCH-Fuel'
                                ) {
                                    return null;
                                }

                                const tableData = [
                                    {
                                        key: `${genIndex}-${equipIndex}`,
                                        name: genName || 'Unknown',
                                        batteryVoltage: generatorBattery,
                                        lastUpdated: lastUpdated,
                                        status:
                                            notliveData || generatorBattery < 0 ? 'Unknown/Offline' :
                                                generatorBattery === 0
                                                    ? 'No Voltage'
                                                    : generatorBattery < 10
                                                        ? 'Low'
                                                        : generatorBattery < 12
                                                            ? 'Moderate'
                                                            : 'Good',
                                    },
                                ];

                                const columns = [
                                    { title: 'Name', dataIndex: 'name', key: 'name' },
                                    { title: 'Battery Voltage', dataIndex: 'batteryVoltage', key: 'batteryVoltage' },
                                    { title: 'Status', dataIndex: 'status', key: 'status' },
                                    { title: 'Last Updated ' + `(${selectedTimezoneAbb})`, dataIndex: 'lastUpdated', key: 'lastUpdated' },
                                ];

                                const fillColor =
                                    notliveData || generatorBattery < 0 ? grey :
                                        generatorBattery === 0
                                            ? grey
                                            : generatorBattery < 10
                                                ? red
                                                : generatorBattery < 12
                                                    ? '#F19D00'
                                                    : green;

                                const key = `gen-${genIndex}-equip-${equipIndex}`;

                                const genId = items.data[0]?.equipment_id

                                return (
                                    <Popover
                                        content={
                                            <Table
                                                columns={columns}
                                                dataSource={tableData}
                                                pagination={false}
                                                size="small"
                                                bordered
                                            />
                                        }
                                        key={key}
                                        color="white"
                                        overlayInnerStyle={{ padding: 0 }}
                                    >
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            {iconTheme === 0 ? (
                                                <Link
                                                    to={`/${selectedCustomerName}/${record.name}/${POWER_GENERATION}/${genId}`}
                                                    onClick={() => dispatch(setSelectedGeneratorId(genId), dispatch(setSelectedSourceId(items.data[0]?.source_id)))}
                                                    style={{ marginRight: 5 }}
                                                >
                                                    <ColorCircle
                                                        value={_.round(generatorBattery)}
                                                        color={fillColor}
                                                    />
                                                </Link>
                                            ) : (
                                                <>
                                                    <MainIconTemp3 fillColor={fillColor} />
                                                    <Link
                                                        to={`/${selectedCustomerName}/${record.name}/${POWER_GENERATION}/${genId}`}
                                                        style={{ marginLeft: 5 }}
                                                    >
                                                        {genName}
                                                    </Link>
                                                </>
                                            )}
                                        </div>
                                    </Popover>
                                );
                            })
                        )}
                    </Space>
                );
            },
        },
        {
            title: 'COMM',
            key: '8',
            dataIndex: 'comm',
            render: (text, record) => {
                const status = record?.location?.status === 1;

                const timeStamp = record?.location?.last_update_at;
                const lastUpdated = timeStamp
                    ? dayjs(timeStamp).format('YYYY-MM-DD HH:mm:ss')
                    : 'N/A';

                // Calculate time difference in minutes (both in UTC)
                const now = dayjs().utc();
                const timeDiff = now.diff(dayjs.utc(timeStamp), 'minutes');

                // Determine the circle color based on the time difference
                const circleColor = status ? green : red;

                // Table columns for Tooltip
                const tableColumns = [
                    {
                        title: 'Last Updated ' + `(${selectedTimezoneAbb})`,
                        dataIndex: 'lastUpdated',
                        key: 'lastUpdated'
                    }
                ];
                const tableData = [
                    {
                        key: '1',
                        lastUpdated: timeStamp
                            ? selectedTimezone?.includes('UTC') ? dayjs(timeStamp).format('YYYY-MM-DD HH:mm:ss') : dayjs(timeStamp).tz(selectedTimezone).format('YYYY-MM-DD HH:mm:ss')
                            : null
                    }
                ];

                return (
                    <Popover
                        overlayInnerStyle={{ padding: 0 }}
                        content={
                            <Table
                                columns={tableColumns}
                                dataSource={tableData}
                                size="small"
                                pagination={false}
                                bordered
                            />
                        }
                    >
                        <>
                            <ColorCircle color={circleColor} value={-123} />
                        </>
                    </Popover>
                );
            },
        }
        ,
        {
            title: 'TRAVEL MODE',
            key: '9',
            dataIndex: 'travel',
            render: () => {
                // const engineHours = record.generators[0]?.liveValues.find((item: any) => item.value_name === 'Engine Hours')?.value;
                return iconTheme === 0 ? <ColorCircle color={grey} value={-1} /> : 'N/A';
            },
        },
    ];

    // const defaultCheckedList = columns.map((item) => item.key);

    const defaultCheckedList = ['0', '1', '2', '3', '5', '7', '8'];

    const [checkedList, setCheckedList] = useState(defaultCheckedList);

    const options = columns.map(({ key, title }) => ({
        label: title,
        value: key,
        key: key
    }));

    const newColumns = columns.map((item, key) => ({
        ...item,
        key: key,
        hidden: !checkedList.includes(item.key as string),
    }));

    const handleSelectCustomerChange = (value: string) => {
        dispatch(setSelectedCustomerName(value));
        dispatch(setSelectedTimezone(permittedCustomers.find(data => data.customer === value)?.preferences?.timezone || 'US/Pacific'));
        dispatch(clearDashboardData())
        dispatch(clearUserInfo());
        dispatch(clearPowerState());
        dispatch(clearChargerData());
    }

    //Dashboard API
    useEffect(() => {
        const pollApis = () => {
            if (
                (
                    selectedCustomerName === customer &&
                    !_.isEmpty(selectedCustomerName) &&
                    detailedChargerData.all_connected_chargers.length === 0 && detailedChargerData.session.length === 0 && detailedChargerData.connector.length === 0 &&
                    detailedGeneratorData.length === 0
                )) {
                dispatch(fetchAllCustomerSites(selectedCustomerName))
                dispatch(fetchBPLiveDataForCustomer({ selectedCustomerName }));
                dispatch(fetchdetailedSummaryCharger({ selectedCustomerName }));
            }
        };
        pollApis(); // Call immediately on mount
        return () => {
        };
    }, [selectedCustomerName, customer]);

    const location = useLocation();

    const currentPath = location.pathname;

    const [selectedKey, setSelectedKey] = useState<string>(currentPath.toLowerCase().includes('maps') ? 'MAPS' : 'DASHBOARD');

    const items2: MenuProps['items'] = [
        // {
        //     key: '0',
        //     label: 'INTRODUCTION',
        //     icon: <ExclamationCircleOutlined className="colorChangeWhenSelected" />,
        //     onClick: () => (setOpenModal(true), setSelectedKey('INTRODUCTION')),
        //     onTitleClick: () => (setShowMaps(false), setSelectedKey('INTRODUCTION')),
        // },
        {
            key: '1',
            label: 'DASHBOARD',
            icon: <img src={selectedKey === 'DASHBOARD' ? globalDashboardGreen : globalDashboard} className='colorChangeWhenSelected' />,
            onClick: () => (setShowMaps(false), setSelectedKey('DASHBOARD')),
            onTitleClick: () => (setShowMaps(false), setSelectedKey('DASHBOARD')),
        },
        {
            key: '2',
            label: 'MAPS',
            icon: <img src={selectedKey === 'MAPS' ? MapsGreen : Maps} className='colorChangeWhenSelected' />,
            onClick: () => (setShowMaps(true), setSelectedKey('MAPS')), //setShowMaps(true),
            onTitleClick: () => (setShowMaps(true), setSelectedKey('MAPS')), //setShowMaps(true)
        }
    ]

    const {
        token: { colorBgContainer },
    } = theme.useToken();


    const handleSiderClose = () => {
        setCollapsed(!collapsed);
    };

    const handleIntroductionClick = () => {
        setOpenModal(true);
    }

    const changeTheme = (checked: boolean) => {
        dispatch(setIconTheme(checked ? 1 : 0));
    };

    // Define the menu items in the new format
    const items: MenuProps['items'] = [
        {
            key: 'profile',
            label: (
                <span>
                    <SolutionOutlined /> View Profile
                </span>
            ),
        },
        {
            key: 'switch',
            label: (
                <div>
                    Theme : &nbsp;
                    <Switch
                        checkedChildren="circle"
                        unCheckedChildren="simple"
                        defaultChecked={iconTheme === 0 ? false : true}
                        onChange={(e) => changeTheme(e)}
                    />
                </div>
            ),
        },
        // {
        //     key: 'Introduction',
        //     onClick: handleIntroductionClick,
        //     label: (
        //         <span>
        //             <img src={IntroLogo} alt="introduction" className='h-13' /> Introduction
        //         </span>
        //     ),
        // },
        {
            key: 'logout',
            onClick: signOut,
            label: (
                <span>
                    <PoweroffOutlined /> Log Out
                </span>
            ),
        },
    ];

    const logo = collapsed ? LogoSmall : LogoDark;

    const isDisabled = permittedCustomers.length === 1 || permittedCustomersLoading;

    useEffect(() => {
        if (selectedCustomerName) {
            document.title = (permittedCustomers && permittedCustomers.filter(item => item.customer === selectedCustomerName)[0].name ?
                permittedCustomers.filter(item => item.customer === selectedCustomerName)[0].name : selectedCustomerName) + ' - ' + 'e-Boost' || 'e-BoostRealM';
        }
    }, [selectedCustomerName]);

    useEffect(() => {
        if (userInfo?.show_intro === true) {
            setOpenModal(true)
        }
    }, [userInfo])

    const VersionItem = () => (
        <>
            <div>
                Ver 2.0 API 2.45
            </div>
        </>
    );

    const siderStyle: React.CSSProperties = {
        overflow: 'auto',
        height: '100vh',
        position: 'fixed',
        insetInlineStart: 0,
        top: 0,
        bottom: 0,
        scrollbarWidth: 'thin',
        scrollbarGutter: 'stable',
        display: 'table'
    };


    const siderStyleNoTable: React.CSSProperties = {
        overflow: 'auto',
        height: '100vh',
        position: 'fixed',
        insetInlineStart: 0,
        top: 0,
        bottom: 0,
        scrollbarWidth: 'thin',
        scrollbarGutter: 'stable',
        // display: 'table'
        overflowY: 'hidden'
    };

    const onSearch = (value: string) => {
        setSearchQuery(value);
    }

    const { Title } = Typography;

    return (
        <div>
            {[undefined, null, ""].includes(site) ?
                <div className="dashboard general">
                    <Layout>
                        {isMobile ? (
                            <>
                                <Drawer
                                    title={<div className="space-between-center">
                                        <div className="flex-center">
                                            <Button
                                                type="text"
                                                icon={collapsed ? <MenuFoldOutlined /> : <MenuUnfoldOutlined />}
                                                onClick={() => handleSiderClose()}
                                                className='mr-10 text-base'
                                            />
                                            <div>Menu</div>
                                        </div>
                                        <div className='imageDiv'>
                                            <img
                                                src={logo}
                                                alt="logo"
                                                className='actualImage'
                                            />
                                        </div>
                                    </div>}
                                    placement="left"
                                    closable={false}
                                    onClose={() => setCollapsed(!collapsed)}
                                    visible={collapsed}
                                >   {!isMobile && <div className='imageDiv'>
                                    <img
                                        src={logo}
                                        alt="logo"
                                        className='actualImage'
                                    />
                                </div>}
                                    {isMobile && <div className="selectDiv">
                                        <Select
                                            value={selectedCustomerName}
                                            loading={permittedCustomersLoading}
                                            showSearch
                                            options={permittedCustomers.map(item => ({ label: item.name, value: item.customer }))}
                                            suffixIcon={<UserOutlined className='h-18' />}
                                            onChange={handleSelectCustomerChange}
                                            disabled={isDisabled}
                                            className={isDisabled ? 'insideSelectMobile select-disabled' : 'insideSelectMobile'}
                                        />
                                    </div>}
                                    <Menu
                                        theme={isMobile ? "light" : "dark"}
                                        mode="inline"
                                        defaultSelectedKeys={['1']}
                                        defaultOpenKeys={['1']}
                                        style={{ height: '100%', borderRight: 0 }}
                                        items={userInfo?.show_intro ? items2 : items2.filter(item => item?.key !== '0')}
                                    />
                                </Drawer>
                            </>
                        ) : (<Sider trigger={null} collapsible collapsed={collapsed} theme={isMobile ? "light" : "dark"} style={!collapsed ? siderStyle : siderStyleNoTable}>

                            {!isMobile && <div className='imageDiv'>
                                <img
                                    src={logo}
                                    alt="logo"
                                    className='actualImage'
                                />
                            </div>}
                            {isMobile && <div className="selectDiv">
                                <Select
                                    value={selectedCustomerName}
                                    loading={permittedCustomersLoading}
                                    showSearch
                                    options={permittedCustomers.map(item => ({ label: item.name, value: item.customer }))}
                                    suffixIcon={<UserOutlined className='h-18' />}
                                    onChange={handleSelectCustomerChange}
                                    disabled={isDisabled}
                                    className={isDisabled ? 'insideSelectMobile select-disabled' : 'insideSelectMobile'}
                                />
                            </div>}

                            <Menu
                                theme={isMobile ? "light" : "dark"}
                                mode="inline"
                                defaultSelectedKeys={['1']}
                                defaultOpenKeys={['1']}
                                style={{ height: '100%', borderRight: 0 }}
                                items={userInfo?.show_intro ? items2 : items2.filter(item => item?.key !== '0')}
                            />
                            <div className='versionDiv'>
                                <VersionItem />
                            </div>
                        </Sider>)}

                        {!showMaps ? <Layout style={{ marginInlineStart: isMobile ? 0 : collapsed ? 80 : 200, height: 'inherit' }}>
                            <Header className="header" style={{
                                position: 'sticky',
                                top: 0,
                                zIndex: 1,
                                width: '100%',
                                height: '64px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                background: colorBgContainer
                            }}>

                                {/* padding: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px; */}

                                <div className='collapseDiv'>
                                    <Button
                                        type="text"
                                        icon={collapsed ? isMobile ? <MenuFoldOutlined /> : <MenuUnfoldOutlined /> : isMobile ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                                        onClick={() => handleSiderClose()}
                                        className='mr-10 text-base'
                                    />
                                    {!isMobile && <Select
                                        value={selectedCustomerName}
                                        loading={permittedCustomersLoading}
                                        showSearch
                                        size="large"
                                        options={permittedCustomers.map(item => ({ label: item.name, value: item.customer }))}
                                        suffixIcon={<UserOutlined style={{ fontSize: '18px' }} />}
                                        onChange={handleSelectCustomerChange}
                                        disabled={isDisabled}
                                        className={isDisabled ? 'insideSelect select-disabled' : 'insideSelect'}
                                    />}
                                    {/* <div>
                                        <ReloadOutlined onClick={() => navigate(0)} />
                                    </div> */}
                                </div>
                                <div className='collapseDiv'>
                                    <Button
                                        type="text"
                                        icon={<BellOutlined className='text-base' />}
                                        className='mr-10 text-base'
                                    >
                                        <span className={collapsed ? '' : 'noShow'}>Notification</span>
                                    </Button>
                                    <div className='pointer'>
                                        <Dropdown menu={{ items }}>
                                            <div>
                                                <Avatar
                                                    size={'small'}
                                                    icon={<UserOutlined />}
                                                    className='avatarAdditional'
                                                >
                                                    {userInfo?.first_name && userInfo.first_name.charAt(0)}
                                                </Avatar>
                                                <span className={collapsed ? 'mr-10' : 'mr-10 noShow'}>{userInfo?.first_name}</span>
                                            </div>
                                        </Dropdown>
                                    </div>
                                </div>
                            </Header>
                            <Content className="content">
                                {<div>
                                    <Row gutter={[16, 16]} className="full-height-row">
                                        {/* Left Side */}
                                        <Col {...responsiveColCards}>
                                            <Row gutter={[16, 16]}>
                                                <Col {...responsiveColCardsInner}>
                                                    <Card style={{ minHeight: '142px', display: 'flex', alignItems: 'center', justifyContent: 'center' }} size='small' >
                                                        {
                                                            permittedCustomers.filter(item => item.customer === selectedCustomerName)[0]?.customer_logo ? <img src={permittedCustomers.filter(item => item.customer === selectedCustomerName)[0]?.customer_logo} alt="customerLogo" style={{ height: '110px', width: '100%' }} /> : <Title >{permittedCustomers.filter(item => item.customer === selectedCustomerName)[0]?.name}</Title>
                                                        }
                                                    </Card>
                                                </Col>
                                                <Col {...responsiveColCardsInner}>
                                                    <Card className="NoHeaderBorder" style={{ minHeight: '142px' }} size='small' title={_.toUpper("Hours Spent Charging (WEEK)")} extra={<img src={hrsSpnt} alt="fuel" className='h-40' />}>
                                                        <Spin size="large" spinning={(permittedCustomersLoading || (customerSites.length === 0 && customerSitesLoading) || loadingDetailedChargerData)}><Typography className="typoNumber">{getHoursSpentChargingWeek()}</Typography></Spin>
                                                    </Card>
                                                </Col>
                                            </Row>
                                            {/* Space between the two rows */}
                                            <Row gutter={[16, 16]} style={{ marginTop: '16px' }}>
                                                <Col {...{ ...responsiveColCardsInner }}><Card className="NoHeaderBorder" style={{ minHeight: '142px' }} size='small' title={_.toUpper("Charging Sessions (week)")} extra={<img src={chgTdy} alt="generator" className='h-40' />}>
                                                    <Spin size="large" spinning={(permittedCustomersLoading || (customerSites.length === 0 && customerSitesLoading) || loadingDetailedChargerData)}><Typography className="typoNumber">{getChargingSessionsPerWeek()}</Typography></Spin>
                                                </Card></Col>
                                                <Col {...responsiveColCardsInner}>
                                                    <Card style={{ minHeight: '142px' }} className="NoHeaderBorder" size='small' title={_.toUpper("KWh Generated")} extra={<img src={kwhgen} alt="battery" className='h-40' />}>
                                                        <Spin size="large" spinning={(permittedCustomersLoading || (customerSites.length === 0 && customerSitesLoading) || loadingGenDetails)}><Typography className="typoNumber"><Tooltip color="white" overlayInnerStyle={{ padding: 0 }} title={getSiteNamesWithKwh()}>{totalKwh}</Tooltip></Typography></Spin>
                                                    </Card>
                                                </Col>
                                            </Row>
                                        </Col>
                                        {/* Right Side - Full Height */}
                                        <Col {...responsiveColCards} className="right-full-height">
                                            <Card size='small' className="full-height-card">
                                                <Spin size="large" spinning={(permittedCustomersLoading || (customerSites.length === 0 && customerSitesLoading)) && !loadingLength}><MapsGlobal loading={(loadingDetailedChargerData || loadingGenDetails) && !loadingLength} enableControls={false} main={false} /></Spin>
                                            </Card>
                                        </Col>
                                    </Row>
                                    <Row gutter={[16, 16]} style={{ marginTop: '20px' }}>
                                        <Col xs={24} sm={24} md={24} lg={24}>
                                            <Row gutter={[16, 16]} style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <Col span={21}>
                                                    <Input onChange={(e) => onSearch(e.target.value)} addonBefore={<SearchOutlined />} placeholder="Search by location/ Available/ Run/ Etc..." />
                                                </Col>
                                                <Col>
                                                    <Popover
                                                        content={<Row gutter={[16, 16]} style={{ width: '250px' }}>
                                                            <Col>
                                                                <Checkbox.Group
                                                                    value={checkedList}
                                                                    options={options as CheckboxOptionType[]}
                                                                    onChange={(value) => {
                                                                        setCheckedList(value as string[]);
                                                                    }}
                                                                />
                                                            </Col>
                                                        </Row>}
                                                        title="Title"
                                                        trigger="click"
                                                        open={open}
                                                        onOpenChange={handleOpenChange}
                                                    >
                                                        <Button
                                                            type="primary"
                                                            icon={<EyeInvisibleOutlined />}
                                                        //   onClick={() => enterLoading(1)}
                                                        >
                                                            Filter Columns
                                                        </Button>
                                                    </Popover>
                                                </Col>
                                            </Row>
                                        </Col>
                                        {/* <Modal title="Introduction" open={openModal} footer={null} onCancel={() => setOpenModal(false)} width={'75%'} styles={{ body: { height: '720px', overflowY: 'auto' } }} >
                                            <Introduction />
                                        </Modal> */}
                                        <Col xs={24} sm={24} md={24} lg={24}>
                                            <Table locale={{ emptyText: <Empty description="No Sites added for this customer." /> }} scroll={{ x: true }} size="small" pagination={false} loading={(permittedCustomersLoading || (customerSites.length === 0 && customerSitesLoading) || loadingDetailedChargerData || loadingGenDetails) && !loadingLength} key={'table'} columns={newColumns} dataSource={searchQuery ? generatorData.sort((a, b) => a.name.localeCompare(b.name)) : filteredData.sort((a, b) => a.name.localeCompare(b.name))} />
                                        </Col>
                                    </Row>
                                </div>}
                            </Content>
                        </Layout> : <Layout style={{ marginInlineStart: isMobile ? 0 : collapsed ? 80 : 200, height: 'inherit' }}>
                            <MapsGlobal enableControls={true} main={true} />
                        </Layout>}
                    </Layout>        </div >
                : <Outlet />}
        </div >
    );
}

export default GlobalDashboard3;
