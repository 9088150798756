import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchdetailedSummaryCharger, fetchBPLiveDataForCustomer, fetchBPLiveDataForCustomerForOneSite, fetchdetailedSummaryChargerForSite } from '../services/dashboardService';
import { detailedChargerDataInterface, SessionData } from '../types/chargerTypes';
import { detailedGeneratorData, LiveDataItem } from '../types/powerTypes';
import _ from 'lodash';

// Define the ChargerState type
interface ChargerState {
    detailedChargerData: detailedChargerDataInterface;
    loadingDetailedChargerData: boolean;
    detailedChargerDataForSite: detailedChargerDataInterface;
    chargersId: number[];
    loadingDetailedChargerDataForSite: boolean;
    error: string | null;
    detailedGeneratorData: detailedGeneratorData[];
    detailedGeneratorDataForSite: detailedGeneratorData[];
    loadingGenDetails: boolean;
    loadingGenDetailsForSite: boolean;
    errorGenDetails: string | null;
    errorGenDetailsForSite: string | null;
}

// Initialize the state
const initialState: ChargerState = {
    detailedChargerData: { session: [], connector: [], all_connected_chargers: [] },
    loadingDetailedChargerData: false,
    error: '',
    chargersId: [],
    detailedGeneratorDataForSite: [],
    errorGenDetailsForSite: '',
    detailedGeneratorData: [],
    loadingGenDetailsForSite: false,
    loadingGenDetails: false,
    errorGenDetails: '',
    detailedChargerDataForSite: { session: [], connector: [], all_connected_chargers: [] },
    loadingDetailedChargerDataForSite: false
};

// Create the slice
const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        clearDashboardData(state) {
            return initialState
        },
        clearGeneralData(state) {
            state.detailedChargerDataForSite = { session: [], connector: [], all_connected_chargers: [] };
            state.detailedGeneratorDataForSite = [];
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchdetailedSummaryCharger.pending, (state) => {
                state.loadingDetailedChargerData = true; // Loading starts
                state.error = null; // Reset error
            })
            // Fulfilled: Set loading to false and store the fetched data
            .addCase(fetchdetailedSummaryCharger.fulfilled, (state, action: PayloadAction<detailedChargerDataInterface>) => {
                state.loadingDetailedChargerData = false; // Loading ends
                state.detailedChargerData = action.payload; // Store session data
            })
            // Rejected: Set loading to false and store the error message
            .addCase(fetchdetailedSummaryCharger.rejected, (state, action) => {
                // state.isLoadingSessionData = false; // Loading ends
                state.error = action.payload || 'Failed to fetch session data'; // Store the error
                if (action.payload && (action.payload === 'Request canceled')) {
                    state.loadingDetailedChargerData = true;
                }
                else state.loadingDetailedChargerData = false;
            }).addCase(fetchdetailedSummaryChargerForSite.pending, (state) => {
                state.loadingDetailedChargerDataForSite = true; // Loading starts
                state.error = null; // Reset error
            })
            // Fulfilled: Set loading to false and store the fetched data
            .addCase(fetchdetailedSummaryChargerForSite.fulfilled, (state, action: PayloadAction<detailedChargerDataInterface>) => {
                state.loadingDetailedChargerDataForSite = false; // Loading ends
                state.detailedChargerDataForSite = action.payload; // Store session data
                state.chargersId = _.compact(_.map(action.payload.all_connected_chargers, "id"));
            })
            // Rejected: Set loading to false and store the error message
            .addCase(fetchdetailedSummaryChargerForSite.rejected, (state, action) => {
                // state.isLoadingSessionData = false; // Loading ends
                state.error = action.payload || 'Failed to fetch session data'; // Store the error
                if (action.payload && (action.payload === 'Request canceled')) {
                    state.loadingDetailedChargerDataForSite = true;
                }
                else state.loadingDetailedChargerDataForSite = false;
            }).addCase(fetchBPLiveDataForCustomer.pending, (state) => {
                state.loadingGenDetails = true; // Loading starts
                state.errorGenDetails = null; // Reset error
            }).addCase(fetchBPLiveDataForCustomer.fulfilled, (state, action: PayloadAction<detailedGeneratorData[]>) => {
                state.loadingGenDetails = false; // Loading ends
                state.detailedGeneratorData = action.payload; // Store session data
            }).addCase(fetchBPLiveDataForCustomer.rejected, (state, action) => {
                // state.isLoadingSessionData = false; // Loading ends
            }).addCase(fetchBPLiveDataForCustomerForOneSite.pending, (state) => {
                state.loadingGenDetailsForSite = true; // Loading starts
                state.errorGenDetailsForSite = null; // Reset error
            }).addCase(fetchBPLiveDataForCustomerForOneSite.fulfilled, (state, action: PayloadAction<detailedGeneratorData[]>) => {
                state.loadingGenDetailsForSite = false; // Loading ends
                state.detailedGeneratorDataForSite = action.payload; // Store session data
            }).addCase(fetchBPLiveDataForCustomerForOneSite.rejected, (state, action) => {
                // state.isLoadingSessionData = false; // Loading ends
            })
    },
});

export const { clearDashboardData, clearGeneralData } = dashboardSlice.actions;

export default dashboardSlice.reducer;
