// src/services/customerService.ts
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios, { CancelTokenSource } from 'axios';
import { UserInfo, PermittedCustomer, CustomerSite, UpdateShowIntroStatusResponse, UpdateShowIntroStatusPayload, GeneralDetails, FetchGeneralDetailsPayload } from '../types/customerTypes';
import { clearCookie } from '../../utils/common';

const protocol: any = process.env.REACT_APP_PROTOCOL;
const url: any = process.env.REACT_APP_API_URL;
const baseUrl = protocol + url;

let isPollingActive = false;
let pollingInterval: NodeJS.Timeout;

export const logoutUser = createAsyncThunk(
    'auth/logout',
    async (_, { rejectWithValue }) => {
        try {
            const response = await axios.post(`${baseUrl}oauth/logout/`, {}, { withCredentials: true });
            localStorage.removeItem('user');
            clearCookie();
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response?.data || 'Logout failed');
        }
    }
);

// Async thunk to fetch user info
export const fetchUserInfo = createAsyncThunk<UserInfo, void, { rejectValue: string }>(
    'userInfo/fetchUserInfo',
    async (_, thunkAPI) => {
        try {
            const response = await axios.get<UserInfo>(`${baseUrl}customer/userinfo/`, { withCredentials: true });
            return response.data;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

// Async thunk to fetch permitted customers
export const fetchAllPermittedCustomers = createAsyncThunk<PermittedCustomer[], void, { rejectValue: string }>(
    'userInfo/fetchAllPermittedCustomers',
    async (_, thunkAPI) => {
        try {
            const { data } = await axios.get<PermittedCustomer[]>(`${baseUrl}microapi/permitted_customers/`, { withCredentials: true });

            if (isPollingActive) {
                clearInterval(pollingInterval); // Stop polling if it's active
                isPollingActive = false; // Reset polling flag
            }

            return data;
        } catch (error: any) {
            if (error.response === undefined) {
                return thunkAPI.rejectWithValue(error.message);
            }
            if (error.response.status === 401) {
                clearCookie();
            }

            if (error.response.status === 403) {
                if (!isPollingActive) {
                    startPollingFetchAllPermittedCustomers(thunkAPI.dispatch); // Start polling if it's not active
                    isPollingActive = true; // Set polling flag
                }
            }

            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

// Function to start polling
function startPollingFetchAllPermittedCustomers(dispatch: any) {
    pollingInterval = setInterval(() => {
        dispatch(fetchAllPermittedCustomers());
    }, 5000); // Poll every 5 seconds (adjust as needed)
}

let cancelTokenSource: CancelTokenSource | null = null;
let pollingInterval2: NodeJS.Timeout | null = null;

// Async thunk to fetch customer sites
export const fetchAllCustomerSites = createAsyncThunk<
    CustomerSite[],
    string,
    { rejectValue: string }
>(
    'sites/fetchAllCustomerSites',
    async (selectedCustomerName, thunkAPI) => {
        if (cancelTokenSource) {
            cancelTokenSource.cancel('Request canceled');
            cancelTokenSource = null; // Reset after cancellation
        }

        cancelTokenSource = axios.CancelToken.source();

        try {
            const response = await axios.get<CustomerSite[]>(`${baseUrl}crud/get_customersites`, {
                withCredentials: true,
                headers: {
                    'customer': selectedCustomerName,
                },
                cancelToken: cancelTokenSource.token,
            });

            if (response.status === 200) {
                startPollingCustomerSites(selectedCustomerName, thunkAPI.dispatch);
            }

            return response.data;
        } catch (error: any) {
            if (axios.isCancel(error)) {
                console.log('Request canceled:', error.message);
                return thunkAPI.rejectWithValue('Request canceled');
            }

            return thunkAPI.rejectWithValue(error.response?.data?.message || error.message);
        } finally {
            cancelTokenSource = null; // Cleanup
        }
    }
);

export const startPollingCustomerSites = (
    selectedCustomerName: string,
    dispatch: any,
    interval: number = 120000 // Default 3 minutes
) => {
    // Clear any existing polling
    stopPollingCustomerSites();

    // Start a new polling interval
    pollingInterval2 = setInterval(() => {
        dispatch(fetchAllCustomerSites(selectedCustomerName));
    }, interval);

    // Immediately fetch data before the first interval
};

// Action to stop polling
export const stopPollingCustomerSites = (): void => {
    if (pollingInterval2) {
        clearInterval(pollingInterval2);
        pollingInterval2 = null;
    }
    if (cancelTokenSource) {
        cancelTokenSource.cancel('Request canceled');
        cancelTokenSource = null;
    }
};

export const updateShowIntroStatus = createAsyncThunk<
    UpdateShowIntroStatusResponse,          // Return type on success
    UpdateShowIntroStatusPayload,           // Argument type
    { rejectValue: string }                // Type for rejectWithValue
>(
    'customer/updateShowIntroStatus',
    async (payload, thunkAPI) => {
        try {
            const response = await axios.patch<UpdateShowIntroStatusResponse>(
                `${baseUrl}customer/userinfo/`,
                payload,
                { withCredentials: true }
            );
            return response.data;
        } catch (error: any) {
            if (axios.isAxiosError(error)) {
                // Extract a meaningful error message from the response
                return thunkAPI.rejectWithValue(
                    error.response?.data?.message || error.message
                );
            }
            // Fallback error message
            return thunkAPI.rejectWithValue('An unknown error occurred');
        }
    }
);

export const fetchGeneralDetails = createAsyncThunk<
    GeneralDetails, // Return type of the fulfilled action
    FetchGeneralDetailsPayload, // Argument type for the thunk
    { rejectValue: string } // Reject value type in case of error
>('sites/fetchGeneralDetails', async (payload, thunkAPI) => {
    const { selectedCustomerName, avail_sites, selectedSite } = payload;

    const findSiteId = (selectedSite: string) =>
        avail_sites.find((site) => site.name === selectedSite);

    const siteid = findSiteId(selectedSite);

    if (!siteid) {
        return thunkAPI.rejectWithValue('Site not found');
    }

    try {
        const { data } = await axios.get<GeneralDetails>(
            `${baseUrl}microapi/generaldetails/${siteid.id}/`,
            {
                withCredentials: true,
                headers: {
                    'customer': selectedCustomerName, // Include the customer attribute in the headers
                },
            }
        );
        return data;
    } catch (error: any) {
        return thunkAPI.rejectWithValue(error.message);
    }
});

type LiveValue = {
    value_datetime: string;
    value_name: string;
    value: number;
    value_type: "binary" | "analog";
    equipment_name: string;
    equipment_id: number;
};

type Generator = {
    equipment_name: string;
    equipment_id: number;
    liveValues: LiveValue[];
};

type ChargerModel = {
    id: number;
    vendor: string;
    model: string;
    capacity: number;
    charger_type: string;
    connectors: number;
    image_src: string;
    charger_link: string;
};

type ChargerStatus = {
    id?: number;
    name?: string;
    location?: any;
    connectors?: any;
    endpoint?: string;
    event_time?: string | null;
    charger?: number | null;
};

type Charger = {
    id: number;
    charger_title: string;
    charger_model: ChargerModel;
    serial_number: string;
    station_id: string;
    station_name: string;
    api_source: string;
    extra_data: Record<string, unknown>;
    status: ChargerStatus;
};

export interface Site {
    id: number;
    name: string;
    generators: Generator[];
    chargers: Charger[];
};


let cancelTokenSource4: CancelTokenSource | null = null;
const WEATHER_API_KEY = "ba96d1197b0aacd5be67f7f2318ac7c6";

export const fetchWeatherData = createAsyncThunk<
    any, // Adjust this type based on the expected response structure
    { lat: number; lon: number },
    { rejectValue: string }
>(
    'weather/fetchWeatherData',
    async ({ lat, lon }, thunkAPI) => {
        // Cancel any previous request
        if (cancelTokenSource4) {
            cancelTokenSource4.cancel('Request canceled');
            cancelTokenSource4 = null; // Reset after cancellation
        }

        cancelTokenSource4 = axios.CancelToken.source();

        try {
            const response = await axios.get(
                `https://api.openweathermap.org/data/2.5/weather?lat=${lat}&lon=${lon}&appid=${WEATHER_API_KEY}`,
                {
                    cancelToken: cancelTokenSource4.token,
                }
            );

            // Check if response is valid
            if (response.status === 200 && response.data) {
                return response.data; // Return weather data
            } else {
                console.error('Weather data is not available.');
                return thunkAPI.rejectWithValue('Weather data is not available.');
            }
        } catch (error: any) {
            if (axios.isCancel(error)) {
                console.error('Request canceled:', error.message);
                return thunkAPI.rejectWithValue('Request canceled');
            }

            if (axios.isAxiosError(error)) {
                if (error.response) {
                    console.error('Error response:', error.response);
                    return thunkAPI.rejectWithValue(
                        error.response.data?.message || 'Failed to fetch weather data.'
                    );
                } else if (error.request) {
                    console.error('No response received:', error.request);
                    return thunkAPI.rejectWithValue('No response from server.');
                } else {
                    console.error('Error setting up request:', error.message);
                    return thunkAPI.rejectWithValue('Error setting up request.');
                }
            }

            console.error('Unexpected error:', error);
            return thunkAPI.rejectWithValue('Unexpected error occurred.');
        } finally {
            cancelTokenSource4 = null; // Cleanup
        }
    }
);