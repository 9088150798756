import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchPictureListByCamera, Picture } from "../services/cameraService";

interface CameraState {
    customerList: string[] | null; // Assuming customerList is an array of strings, adjust as needed
    cameraInfoList: Picture[];
    cameraInfoDetails: any[]; // Define a more specific type if possible
    isSuccessCustomer: boolean;
    errorMessage: string;
    hideIntroPage: boolean;
    userInfo: any[]; // Define a more specific type if possible
    isLoadingUserInfo: boolean;
    isSuccessUserInfo: boolean;
    selectedCustomerName?: string; // Optional field
}

const initialState: CameraState = {
    customerList: null,
    cameraInfoList: [],
    cameraInfoDetails: [],
    isSuccessCustomer: false,
    errorMessage: '',
    hideIntroPage: false,
    userInfo: [],
    isLoadingUserInfo: false,
    isSuccessUserInfo: false,
}

const cameraSlice = createSlice({
    name: 'camera',
    initialState,
    reducers: {
    },
    extraReducers: builder => {
        builder
            .addCase(fetchPictureListByCamera.pending, state => {
                state.isLoadingUserInfo = true;
            })
            .addCase(fetchPictureListByCamera.fulfilled, (state, action: PayloadAction<Picture[]>) => {
                state.isLoadingUserInfo = false;
                state.isSuccessUserInfo = true;
                state.cameraInfoList = action.payload;
            })
            .addCase(fetchPictureListByCamera.rejected, (state, action: PayloadAction<any>) => {
                state.isLoadingUserInfo = false;
                state.isSuccessUserInfo = false;
                state.errorMessage = action.payload as string;
            });
    }
});

// Export actions
export default cameraSlice.reducer;
