import { Col, Row, Spin, Typography } from 'antd'
import { useEffect } from 'react'
import { Line } from 'react-chartjs-2'
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store';
import { LOWFUEL, REFUELED, VERYLOWFUEL } from '../../utils/constants';
import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';
import { setSelectedFuelId, setSelectedFuelSourceId } from '../../redux/slices/powerSlice';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import {
    Chart as ChartJS,
    LineElement,
    PointElement,
    LinearScale,
    Title,
    CategoryScale,
    Legend,
    Tooltip,
} from 'chart.js';

// Register the necessary components for the Line chart
ChartJS.register(
    LineElement,
    PointElement,
    LinearScale,
    Title,
    CategoryScale,
    Legend,
    Tooltip
);

dayjs.extend(utc);
dayjs.extend(timezone);


const FuelIndividual = () => {
    let latestValueDatetime: any = null;
    const { fuelId }: any = useParams();
    const dispatch = useDispatch<AppDispatch>();
    let largestValue = 100;
    const { permittedCustomers, selectedCustomerName, selectedSite, selectedTimezone } = useSelector((state: RootState) => state.userInfo);
    const { fuelGenList: generatorListfromAPI, loading: isLoadingGeneratorList, graphData: bpSitehistory, graphDataLoading: isLoadingGraphData, selectedTimePeriod, fromDate, toDate, isLoadingBPValues } = useSelector((state: RootState) => state.power);

    useEffect(() => {
        if (generatorListfromAPI.length > 0) {
            if (generatorListfromAPI.filter((data) => data.equipment_id === Number(fuelId)).length > 0) {
                dispatch(setSelectedFuelId(Number(fuelId)));
                dispatch(setSelectedFuelSourceId(generatorListfromAPI.find((data) => data.equipment_id === Number(fuelId))?.source_id === undefined ? '' : generatorListfromAPI.find((data) => data.equipment_id === Number(fuelId))?.source_id + '') || '');
            }
        }
    }, [generatorListfromAPI, fuelId]);

    function setMaxValueForXaxis() {
        for (const dataPoint of bpSitehistory) {
            const valueDatetime = new Date(dataPoint.value_datetime);
            if (!latestValueDatetime || valueDatetime > latestValueDatetime) {
                latestValueDatetime = valueDatetime;
            }
        }
    }
    const options: any = {
        animation: false,
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: "top",
                labels: {
                    boxHeight: 10, // Adjust the height of legend labels
                    boxWidth: 10, // Adjust the width of legend labels
                },
            },
            title: {
                display: true,
                text: getTitle(),
            },
        },
        scales: {
            x: {
                ticks: {
                    precision: 0,
                    maxTicksLimit: 10, // Set the maximum number of ticks you want on the x-axis
                },
                //   display: true,
                max: setMaxValueForXaxis(),
            },
            y: {
                ticks: {
                    precision: 1,
                    maxTicksLimit: 10, // Set the maximum number of ticks you want on the y-axis
                },
                // display: true,
                min: 0,
                max: largestValue,
            },
        },
    };


    function getTitle() {
        // Add your conditional logic here to determine the title based on certain conditions
        return `${permittedCustomers.filter(customer => customer.customer === selectedCustomerName)[0].name || selectedCustomerName} - ${selectedSite} - Fuel History - ${dayjs(fromDate).tz(selectedTimezone).format("MM-DD HH:mm")} to ${dayjs(toDate).tz(selectedTimezone).format("MM-DD HH:mm")}`;
    }
    const formattedFuelHistory = bpSitehistory?.map((hist) => {
        const formattedDatetime = dayjs(hist.value_datetime)
            .tz(selectedTimezone)
            .format("MM-DD HH:mm");
        return formattedDatetime;
    })

    const data: any = {
        labels: formattedFuelHistory,
        datasets: [
            {
                label: 'Fuel Level',
                data: bpSitehistory
                    .map(value => value.value === -99 ? null : value.value),
                borderColor: "blue",
                backgroundColor: "blue",
                pointBackgroundColor: function (context: any) {
                    var index = context.dataIndex;
                    var value = context.dataset.data[index];
                    return value <= VERYLOWFUEL
                        ? "red" // draw peak values in red
                        : value <= LOWFUEL &&
                            value > VERYLOWFUEL
                            ? "orange"
                            : value >= REFUELED
                                ? "green"
                                : "blue";
                },
                pointBorderColor: function (context: any) {
                    var index = context.dataIndex;
                    var value = context.dataset.data[index];
                    return value <= VERYLOWFUEL
                        ? "red" // draw peak values in red
                        : value <= LOWFUEL &&
                            value > VERYLOWFUEL
                            ? "orange"
                            : value >= REFUELED
                                ? "green"
                                : "blue";
                },
            },
            ...('Fuel Level' === "Fuel Level"
                ? [
                    {
                        label: "Very low",
                        borderColor: "red",
                        backgroundColor: "red",
                    },
                    {
                        label: "low",
                        borderColor: "orange",
                        backgroundColor: "orange",
                    },
                    {
                        label: "Refueled",
                        borderColor: "green",
                        backgroundColor: "green",
                    },
                ]
                : []),
        ],
    };

    return (
        <div>
            {!isLoadingGraphData && !isLoadingGeneratorList && !isLoadingBPValues && <div className="w-full">
                {bpSitehistory.length > 0 ? (
                    <Col
                        span={24}
                        style={{
                            height: "700px",
                            width: "100%",
                            overflow: "auto",
                        }}
                    >
                        <Line options={options} data={data} className="lineGraph" />
                    </Col>
                ) : (
                    <Row justify="center">
                        <Col xs={24} sm={22} md={20} lg={18} xl={16} className="loadingColGraph">
                            <Typography.Title level={2} type="secondary">
                                {selectedTimePeriod == "date"
                                    ? "No fuel history found for the given date"
                                    : `No fuel history found for ${selectedTimePeriod}`}
                            </Typography.Title>
                        </Col>
                    </Row>
                )}
            </div>}
            {(isLoadingGraphData || isLoadingGeneratorList || isLoadingBPValues) &&
                <div style={{ width: "100%" }}>
                    <Spin size="large" >
                        <Row justify="center">
                            <Col xs={24} sm={24} md={24} lg={24} xl={24} className="loadingColGraph">
                                <Typography.Title level={2} type="secondary">Preparing chart. Please wait</Typography.Title>
                            </Col>
                        </Row>
                    </Spin>
                </div>}

        </div>
    )
}

export default FuelIndividual